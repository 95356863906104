/*******************************************
Header Styles
*******************************************/

.load-page{
	position: fixed;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	// background: $color-brand;
	// background: $color-grey-darkest;
	background: #131313f9;
	z-index: 9999;
	top:0;
	left:0;
	right:0;
	bottom:0;
	align-items: center;
	justify-content: center;
}

.load-panel {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 99999;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background: linear-gradient(to right, #131313, #131313, #131313,#131313,#070707, #070707,#131313,#131313,#131313, #131313);
	display: flex;
	// background-size: 300% 100%;
	// animation: gradientAnimation 4s linear infinite;

	// background: linear-gradient(45deg, #ff8a00, #e52e71);
	animation: colorFade 1.5s infinite;
}

@keyframes colorFade {
	0%, 100% {
		background: #111111;
	}
	50% {
		background: #070707;
	}
}
@keyframes gradientAnimation {
  0%, 100% {
    background-position: 100% 50%;
  }
  // 50% {
  //   background-position: 50% 50%;
  // }
  100% {
    background-position: 0% 50%;
  }
}

.page-title-subscript {
    font-size: 0.7em; /* Adjust the size as needed */
    vertical-align: super;
}

.brand-logo{
	width:120px;
	height:auto;
}

.profile-panel{
	
    // display: flex;
	// flex-direction: column;
	// left: $sidebar-width-left;
	// &.collapsed{
	// 	left: $sidebar-width-left-collapsed;
	// }
    // background: $color-panel-sub-full;
	// background: rgba(15,17,19,.77);
	padding: 15px 15px 120px 15px;
	overflow-y:auto;
    min-height: calc(100vh - 44px);

	.profile-container{
		padding: 22px;
		// max-width: 440px;
		padding-bottom: 111px;
		max-height: calc(100vh - $main-header-height);
		overflow: auto;
	}

	.input-group{
		max-width: 444px;
	}
}

.count-circle{
	width: 18px;
    height: 18px;
	min-width: 18px;
    padding: 3px;
    font-size: 10px;
	font-weight: 600;
    background: $color-brand;
	color: $color-count-text;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
	&:empty{
		width:0;
		height: 0;
		visibility: hidden;
	}
}

.swap-currency-module{
	display: flex;
	flex-direction: column;

	margin: auto;
	background: $color-panel-main;
	background-image: linear-gradient(302deg, #252e34 0%, #3c4a57 74%);
    border-radius: $radius-lg;
	position: relative;
	max-width: 450px;
	min-width: 320px;
	width: 60vw;
}

.swap-currency-input-container{
	padding:4px;
	position: relative;
}

.swap-currency-header{
	padding: 15px 25px 0px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	h6{
		color:  #768d9f !important;
	}
	.swap-settings{
		cursor: pointer;
		opacity: .5;
		&:hover{
			opacity:1;
		}
	}
}

.swap-currency-input, .swap-currency-output{
	flex: 1;
	margin: 18px;
	margin-top: 0;
	padding: 15px;
	padding-top:22px;

    // min-height: 300px;
    background: $color-panel-sub;
    border-radius: 7px;

	input.large{
		font-family: 'Roboto Mono', monospace;
		font-size: 20px;
	}
	.input-group{
		margin: 10px 0;
    	padding: 0;
	}
}
input.large.slippage{
	font-family: 'Roboto Mono', monospace;
	font-size: 20px;
}

.swap-currency-header,.swap-currency-input, .swap-currency-output{
	h6{
		margin: 0;
	    margin-bottom: 7px;
	    color: #373737;
	}
}

// .swap-currency-input{
// 	margin-bottom:7px;
// }
.swap-currency-output{
	margin-bottom:0px;
	// color: #76869687;
}

.swap-max-button{
	position: absolute;
    top: 50%;
    right: 5px;
    top: 5px;
    display: flex;

	button{
		margin: 0;
		background-color: rgba(21, 61, 111, 0.44);
	    color: rgb(33, 114, 229);
		&:hover{
			background-color: $color-button-action;
			color: $color-white;
		}
	}
}

.swap-footer{
	display: flex;
	flex-direction: row;
	justify: space-between;
	align-items: center;
	padding-right: 33px;
	.swap-meta{
		font-size: 11px;

	}
	.swap-button{
		width: 100%;
	}
}

.invert-swap-fields-arrow{
	position: absolute;
    width: 44px;
    height: 44px;
    padding: 7px;
    left: 50%;
    top: 50%;
    margin-left: -22px;
    margin-top: -22px;
    // background: rgb(36 39 41 / 99%);
	// background: $color-panel-sub-item-roll;
	background: rgb(21 31 38);

	border-radius: $radius-lg;
	box-shadow: $shadow-tight;
	cursor: pointer;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;


	&:hover{
		background: $color-button-action;
	}
}

.filter-header{
	position: absolute;
	top:0;
	left:0;
	right:0;
	// height: 44px;
	z-index: 99;
	background: $color-panel-sub;
	display: flex;
	padding: 7px;
	button{
		margin:5px;
	}
}

.filter-segment{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	button{
		margin:3px;
	}
}

.header-texture{
    background-image: url(/media/textures/dropbar3.png);
    background-repeat: repeat-x;
    position: absolute;
    width: 100%;
    top:0;
	left: 0;
    height: 50px;
    pointer-events: none;
	margin-top: 0;
	opacity: .44;
}

.small{
	.trending-nav{
		.data-value{
			display:none;
		}
	}
}

.trending-nav{
	background: #0811198c;
}

.list-nav{
	overflow-x: auto;
}
.list-nav::-webkit-scrollbar {
	display: none; /* Optional: hide the scrollbar in WebKit browsers */
}
.data-row{
	display: flex;
	// width: 96%;
	padding: 20px 44px;
	// justify-content: space-around;
	&.bg{
		// background: $color-panel-data;
	}

	border-radius: $radius-sm;
	padding: 3px;
	&.tabs{
		padding: 4px 11px;
		.data-item{
			flex: 1;
	    	padding: 7px 0px;
		}
	}
	&.bookend{
		.data-item{
			&:first-child{
				text-align: left;
				.data-tab-image-container{
					justify-content: flex-start;
				}
			}
			&:last-child{
				text-align: right;
				.data-tab-image-container{
					justify-content: flex-end;
				}
			}
		}
	}

	&.centerlast{
		.data-item{
			
			&:last-child{
				display: flex;
				justify-content: center;
				
			}
		}
	}
	.data-item{
		flex: 1;
    	// padding: 7px 9px;
		text-align: center;
		position: relative;
		.data-tab-image-container{
			justify-content: center;
		}

	}
	.data-tab-image-container{
		width: 100%;
		display: flex;
	}
	.data-tab-image{
		display: flex;
		max-width: 33px;
		max-height: 33px;
		img{
			max-width: 100%;
		}
	}

	&.interactive-items{
		padding: 0;

		&.disabled{
			pointer-events: none;
			filter: saturate(0) brightness(.5);
		}

		.data-item{
			cursor: pointer;
			position: relative;
			flex: 1;
			padding: 7px 11px;
			cursor: pointer;
			background-image: linear-gradient(0deg, rgba(21, 21, 21, 0.54) 0%, rgba(33, 37, 41, 0.54) 99%);
			border-bottom: 1px solid transparent;
			&:hover, &.active{
				// background: $color-panel-button-roll !important;
				// background: $color-panel-button !important;
				// background: $color-panel-data !important;
				border-color: $color-button-action;
				
				background-image: linear-gradient(352deg,$color-panel-data 0%, rgb(53 57 62 / 54%) 99%);
				.data-title{
					color: $color-text !important;
				}

			}
		}
		&.active-underline{
			.data-item{
				border-bottom: 1px solid transparent;
				
				&.active{
					border-bottom: 1px solid $color-brand !important;
				}

				&:hover, &.active{
					border-bottom: 1px solid $color-brand;
					&.sub-nav{
						border-bottom: 1px solid $color-green;
					}
				}

				&.sub-active{
					border-bottom: 1px solid $color-green;
				}

			}
		}

		&.trending{

		}
	}
	&.active{
		// background: $color-panel-button-roll !important;
		background: $color-panel-data !important;
		// padding: 12px;
		.data-title{
			color: $color-text !important;
		}

	}

	h2{
		font-size: 13px;
		font-weight: 600;
    	margin: 0;
	}
	h3{
		text-transform: uppercase;
	    font-weight: bold;
	    font-size: 11px;
		margin: 0;
	}
}
.data-column{
	display: flex;
	flex-direction: column;
	&.right{
		text-align: right;
	}
}
.data-icon{

	&.large{
		width:37px;
		height:37px;
		margin: 5px;
		// min-height: 41px;
	}
	// width:22px;
	// // height:44px;
	// margin: 3px;
	// min-height: 24px;
	// max-width: 27px;
    // max-height: 27px;
	&.round{

		overflow: hidden;
		img{
			border-radius: 50%;
		}
	}
	img{
		max-width: 100%;
		max-height: 100%;
		border-radius: 50%;
	}
}

.data-panel.interactive, .options-menu{
	// border: 1px solid #233137;
	border: 1px solid rgba(37,53,59,.53) !important;
}



.data-panel{
	display: flex;
	.identicon{
		border-radius: 50%;
		max-width: 24px;
    	max-height: 24px;
		// margin-right: 15px;
	}
	.wallet-title{
		font-size: 14px;
		// white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
		font-weight: 600;
	}
	&.row{
		flex-direction: row;
	    align-items: center;
	}

	&.column{
		flex-direction: column;
	    align-items: center;
	}

    background: $color-panel-data;

	&.opaque{
		background: $color-panel-data-opaque;
	}

	.sub-panel{
		background: $color-panel-sub;
	}
	&.transparent{
		background: transparent;
	}
	&.interactive{
		cursor: pointer;
		background: $color-panel-sub-item;
		background-image: linear-gradient(352deg,$color-panel-data 0%, rgb(53 57 62 / 54%) 99%);
		// border: 1px solid transparent;
		&:hover, &.active{
			// background: $color-panel-sub-item-roll;
			background: $color-panel-sub-item-active;
			border-color: $color-brand !important;
		}

		&.solid{
			background: $color-panel-sub-item;
			&:hover, &.active{
				// background: $color-panel-sub-item-roll;
				background: $color-panel-sub-item-active-solid;
			}
		}

		&.active{
	        border-color: $color-button-action;
			cursor: default;
	    }

	}
	padding: 5px 10px;
	&.small{
		padding: 5px 12px;
	}
    border-radius: $radius-md;
    // max-width: 300px;
}
.data-item{
	&.align-right, .align-right{
		
		text-align: right;
		
	}
	&.data-small{
		transform: scale(.88);
	}
	&.row{
		align-items: center;
		display: flex;
		flex-direction: row;
	}
	&.full{
		width: 100%;
	}
	&.center{
		justify-content: center;
	}


	.small{
	    font-size: 0.77rem;
		opacity: .73;
		margin-left: 2px;
		margin-right: 2px;
	}

}


.data-title{
	font-size: 12px;
	font-weight: bold;
	color: $color-data-title;
	line-height: 18px;
	&.highlight{
		color: $color-off-white;
		color: rgb(176 184 191);
		color: rgb(132 180 222);
	}
	&.bright{
		color: rgb(176 184 191);
	}
}
.modal .data-title{
	font-size: 11px;
}

.active .data-value, data-item:hover .data-value{
	// color:aliceblue;
}
.data-value{
	color: $color-brand;
	color: #99b9d0;
	font-size: 12px;
	font-weight: 600;

	&.center{
		text-align: center;
	}
	&.large{
		font-size: 19px;
		font-weight: 500;
		
		.small{
			// font-size: 17px !important;
		}
	}
	&.x-large{
		font-size: 25px;
		font-weight: 500;
		
		.small{
			font-size: 17px !important;
		}
	}
	&.medium{
		font-size: 15px;
		font-weight: 500;
		&.bold{
			font-weight: bold;
		}
		// 
	}
	.small{
		opacity: .5;
		color: $color-text;
	}

	&.mute-first{
		.coin-e, &::first-letter{
			position: relative;
			// font-family: var(--chakra-fonts-mono);
			font-size: 0.8em;
			top: 0.2em;
			padding-inline: 0.2em;
			opacity: .5;
			margin-right: 5px;
		}
	}
}

.data-label{
	&.outline{
		border: 1px solid $color-panel-sub-roll;
	}
	
	padding: 2px 7px;
	border-radius: $radius-sm;
	color: $color-text;
	font-size: .8em;
	opacity: .73;
	&.no-pad{
		padding:0;
	}
}

.breadcrumbs{
	// background: $color-panel-main;
	display: flex;
	flex-direction: row;
	position: absolute;
	top: 15px;
	left: 15px;
	z-index: 33;
}

.breadcrumb{
    background-color: $color-panel-button;
    color: $color-text;
    transition: background-color $input-duration;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 8px;
    border-radius: 4px;
    line-height: 13px;
	margin: 0;
    i{
        font-size: 14px;
    }
    &:hover, &.active{
        background-color: $color-grey-darker;
        color: $color-brand;
        i{
            color: $color-brand;
        }
    }
    &.active{
        cursor: default;
    }
}

.qr-code-container{
	margin-bottom: 20px;
    // width: 100%;
    height: 440px;
	padding: 10px;
	margin: 10px;
    border-radius: $radius-md;
	background: #fff !important;
	height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;

	svg{
		width: 100%;
		height: 100%;
		border-radius: $radius-md;
		path:first-child{
			fill:#fff !important;
		}	
	}
}

.block-panel{
	position: absolute;
    bottom: 15px;
    right: 15px;
    margin: 0;
    display: flex;
	flex-direction: row;
    align-items: center;
    justify-content: center;
    line-height: 11px;
	.data-value{
		font-size: 10px;
	}
	.block-icon{
		font-size: 11px;
		margin-right: 3px;
		letter-spacing: 2px;
		color: #777;

	}
	@include break-max($break-med) {
		display: none;
	}
}

.tooltip{
	font-size: 12px !important;
	color: $color-text !important;
	font-weight: 600 !important;
	background: #1d465a;
	line-height: 22px;
	z-index: 11001;
}


[class^="style_toast-list"], [class*=" style_toast-list"] {
	top: 50%;
	bottom: auto;
}
.toast-message{
	*{
		font-family: "Roboto Mono", "Montserrat","Source Sans Pro", "Helvetica", sans-serif !important;
	}
	

	div{
		font-size: 13px;
		color: #dcdfe2;
		// background: transparent;
		background: #27343b;
		font-weight: 500;
		border-radius: $radius-md;
		// background: $color-panel-sub;
		// background: #273d46;

		min-width: initial;

	}
}

.panel-title{
	padding: 7px;
    font-size: 14px;
    font-weight: 600;
}

.tab-buttons{

	&.full{
		width:100%;
	}
	button{
		border:0;
		margin:0;
		flex:1;
		border-radius: 0;
		svg{
			margin: 0;
		}
	}
}
.status-lights{
	position: absolute;
    right: 77px;
    bottom: 3px;
    display: flex;
    flex-direction: row;
}

.status-circle{
	width:3px;
	height: 3px;
	margin: 3px;
	border-radius: 50%;
	&.green{
		color: $color-green;
	}
	&.red{
		color: $color-red;
	}
	&.orange{
		color: $color-orange;
	}
}

.ticker__element{
	margin-top:7px;
	padding-bottom:14px;

	// background-image: url(/media/textures/dropbar3.png);
	// background-repeat: repeat-x;
    // z-index: 999;


}

.ticker-overlay{
	position: absolute;
	width: 100%;
	// height:160px;
	height:100%;
	background-image: url(/media/textures/micro_grid.png);
	background-repeat: repeat;
	z-index: 33;
	// border-bottom: 2px solid #171717;
	pointer-events: none;
}

.dot-overlay{
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url(/media/textures/micro_dots.png);
	background-repeat: repeat;
	z-index: 34;
	pointer-events: none;
}

.codesmall,.contract-code{
	font-weight: 500;
	line-height: 14px !important;
	// font-family: "Roboto Mono", monospace;
	font: 12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
	// font-size: .5em;
}

.contract-code{
	padding: 11px;
    margin: 11px ! important;
    background: rgba(17, 17, 17, 0.46);
    border-radius: 6px;
    width: 94%;
    word-break: break-word;
    font-size: .7rem !important;
	opacity: .7;
	a{
		border-bottom:1px dotted #47687c;
		&:hover{
			text-decoration: none;
			border-bottom:1px dotted $color-text;
		}
	}
	.small{
		margin-left: 3px;
	}
	.contract-title{
		width: 100%;
	    display: block;
	    color: #ababab;
		font-weight: 600;
    	opacity: .5;
	}
}

.assembly-use-value{
	font-size: 10px;
    margin-left: 5px;
}
.ticker-container{
	position: absolute;
	z-index: 55;
	top: 0;
	right: 0;
	width:100%;
	// min-height: 225px;
	max-height: 225px;
	display: none;
	&.show{
		top:0;
		left:0;
		right:0;

		display: block;
	}

	// background: rgb(2 4 6 / 64%);
	// border-top: 3px solid #111;
	// border-bottom: 3px solid #111;
	background-color: rgb(17 19 21 / 73%);
}
.ticker-item{
	cursor: pointer;
	display: inline-block;
	flex-direction: column;
	padding: 5px 7px;
	position: relative;
	.ticker-item-chain{
		position: absolute;
		top:4px;
		right:4px;
	}

	.small{
		// margin-top: 2px;
		margin-left: 4px;
		margin-right: 4px;
	}


	&:first-child{

	}
	border-radius: 5px;
	.ticker-icon{
		width: 20px;
		height: 20px;
		&.round{
			border-radius: 10px;
		}
	}
	border: 1px solid transparent;
	&:hover, &.active{
		background: #171717bb;
		border: 1px solid $color-brand;
	}
}
.ticker-row{
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}
.ticker-text{
	font-size: 1.3em;
}

.ticker-symbol{
	font-weight: 600;
	margin-left: 5px;
	max-width: 144px;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
	overflow: hidden;
	&.uppercase{
		text-transform: uppercase;
	}
}
.ticker-price{
	margin-left: 7px;
	margin-right: 22px;
	font-weight: 600;
}
.ticker-price::first-letter{
	opacity: .5 !important;
	font-size: .9em;

}

.ticker-text-gfx{
	display: inline-block;
}


.portfolio-container{
	background: transparent;
	flex-direction: column;
	padding: 0 !important;
	width: 100%;
	// max-width: 720px;
	// margin: 4px;
	// border-radius: $radius-md;
	overflow-y: auto;
	.portfolio-header{
		// width: 100%;
		// padding:4px;
		// background: $color-panel-sub;
		// border-top-left-radius: 7px;
    	// border-top-right-radius: 7px;
		padding: 16px 22px 10px 22px;
		*{
			margin:0;
		}

		// position: fixed;
	    // z-index: 9;
	    background: rgba(16,16,16,.48);
	    width: 100%;
	    // backdrop-filter: blur(4px);
		height:144px;
		// margin-top: 4px;
		overflow: hidden;
	}

	.portfolio-tokens{
		// padding: 4px;
		padding-bottom: 44px;
		// padding-top: 144px;
		overflow-x: hidden;
	}

	

	.portfolio-token-item:nth-child(odd){
	    background: $color-panel-sub-item;
	}
	.portfolio-token-item:nth-child(even){
	    background: $color-panel-sub-item-dark;
	}
	.portfolio-token-item{
		// background: $color-panel-sub-item;
		display: flex;
	    flex-direction: column;
	    justify-content: space-between;
		padding:0;
		border-radius: 0;

		&.space{

	        border-radius: $radius-md;
	        margin: 7px;
	    }

		position: relative;
		&:hover{
			background: $color-panel-sub-roll;
		}

		.flex-row{
			cursor: pointer;
		}

		&:hover, &.active{
			background: $color-panel-sub-item-roll;
	        border-color: $color-button-action;
	        background: #090909;
		}
		&.active{
			background: $color-panel-sub-item-active;
			cursor: default;
	        background: #090909;
		}

		.options-menu{
			right: 0;
		}
		// border-bottom: 1px solid $color-border;
	}



}

.no-click{
	pointer-events: none;
}

.dex-table-container {
  // overflow-y: auto;
  overflow: auto;
  // max-height: 400px; /* Adjust the maximum height as needed */
}


.svg-icon{
	img{
		max-width: 100%;
		max-height: 100%;
	}
	svg{
		width: 100% !important;
  		height: 100% !important;
	}
	&.swap-small{
		svg{
			width: 20px;
			height: 20px;
		}

	}
	&.swap-xsmall{
		svg{
			width: 16px;
			height: 16px;
		}

	}
	&.grey{
		filter: saturate(0) brightness(.88);
	}
	&.no-fill{
		svg{
			fill: none;
			path{
				fill:none;
			}
		}
	}

	&.no-stroke{
		svg{
			stroke-width: 0;
			path{
				stroke-width: 0;
			}
		}
	}
}

.dex-row{
	// border: 1px solid transparent;
	// &.active{
	// 	border: 1px solid $color-brand;
	// }
}


table {

  // display: grid;
  //
	// grid-template-columns:
  //   minmax(150px, 3fr)
  //   minmax(150px, 1fr)
  //   minmax(150px, 1fr)
  //   minmax(150px, 1fr)
  //   minmax(150px, 1fr)
	// minmax(150px, 1fr)
  //   minmax(150px, 1fr)
  //   minmax(150px, 1fr)
  //   minmax(150px, 1fr)
	// minmax(150px, 1fr)
  //   minmax(150px, 1fr)
  //   minmax(150px, 1fr)
  //   minmax(150px, 1fr)
	// minmax(150px, 1fr);
}

table thead,
table tbody,
table tr {
  // display: contents;
}


.dex-table-wrapper{
	min-height: calc( 100vh - 88px );
}
.dex-table {

	min-width: 100%;
	border-collapse: collapse;
	table-layout: grid;
	overflow: auto;
	// border-width: 1px;
	// background: $color-panel-data;


	.small{
		opacity: .7;

		&:nth-child(2){
			margin-left: 1px;
		}
	}

	thead {
	  position: -webkit-sticky;
	  position: sticky;
	  top: 0px;
	  // border-bottom: 2px solid #1d1d1d;
	  z-index: 111;

	  tr{
		  th{
			  // background-color: $color-panel-sub-item;
			  background-color: rgb(19 29 40 / 89%);
			//   background-image: url(/media/textures/dropbar3.png);
		      background-repeat: repeat-x;
		    //   backdrop-filter: blur(12px);
			  border-bottom: 1px solid #1d1d1d;
			  padding: 11px 14px;

			  // min-width: 77px;
			  &:not(.noclick){
				  cursor: pointer;
				  &:hover, &.active{
					 background-color: $color-panel-sub-item-roll;
					 
				  }
			  }

			  &.noclick{
				  pointer-events: none;
			  }


			  &:first-child{
				  position: sticky;
				  left:0;
				  z-index: 99;
				  border: 0;
				  // background-color: $color-panel-sub-item-roll;
			  }
		  }
	  }
	}

	th,
	td {
	  padding: 4px 8px;
	  text-align: left;
	  white-space: nowrap;
	  font-size: 12px;
	  font-weight: 600;
	  border-right: 1px solid;
	  border-bottom: 1px solid;
	  border-color: $color-border-dex;

	  &.ellipsis{
		  overflow: hidden;
		  word-break: break-all;
		  text-overflow: ellipsis;
		  white-space: nowrap;

	  }
	  &.mute-first{
		  &:first-letter{
			  opacity: .7;
			  margin-right: 2px;
		  }
	  }

	}

	th {
	  font-weight: bold;
	  position: relative;
	}


	// th span,
	// td span {
	//   white-space: nowrap;
	//   text-overflow: ellipsis;
	//   overflow: hidden;
	//   display: block;
	// }

	tr td {
	  // border-top: 1px solid rgba(44,44,44,.73);
	 
	}





	tbody tr:nth-child(odd) {
		background: $color-panel-sub-item;
		// background: $color-panel-sub-item-dark;
		td:first-child {
			background: $color-panel-sub-item;
			// background-color: $color-modal-background;
		}
	}

	tbody tr:nth-child(even) {
		background-color: $color-panel-sub-item-dark;
		td:first-child {
			background: $color-panel-sub-item-dark;
			// background-color: $color-modal-background;
		}
	}

	tbody td:first-child {
	  position: sticky;
	  left: 0;
	  z-index: 99;
	  overflow: hidden;
	  // background-color: $color-panel-main;
	//   backdrop-filter: blur(7px);
	}
	tbody tr{
		cursor: pointer;
		// border: 1px solid transparent;
		td{
			// border: 1px solid $color-panel-sub;
			// border-left: 0;
			
		}
		&:hover{

			// background: $color-panel-sub-item-roll;

		}
		.active-bar{
			display: none;
		}
		&.active,&:hover{
			.active-bar{
				display: flex;
				position: absolute;
				pointer-events: none;
				left:0;
				top:0;
				bottom:0;
				height: 100%;
				width: 2px;
				background-color: $color-brand;
			}
			background: $color-panel-sub-item-active;
			// border-color: $color-brand;
		}
	}


}

.resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 2px solid transparent;
}

.resize-handle:hover {
  border-color: #272727;
}

.resize-handle.active {
  border-color: #517ea5;
}

.home-panels{
	display: flex;
	// display: grid;
	// grid-template-columns: repeat(3, 1fr);
	flex-direction: row;
	background: $color-panel-main;
	// flex-wrap: wrap;
	padding: 0;
	width: 100%;
	justify-content: flex-start;
	// height: 100%;
	// height: calc( 100vh - 44px );

	// @include break-max($break-lrg) {
	// 	grid-template-columns: repeat(2, 1fr) !important;
	// 	// grid-template-rows: repeat(2, 1fr) !important;
	// 	// .provider-logo{
	// 	// 	transform: scale(.9);
	// 	// }
	// }
	//
	// @include break-max($break-med) {
	// 	grid-template-columns: repeat(1, 1fr) !important;
	// 	// grid-template-rows: repeat(2, 1fr) !important;
	// 	// .provider-logo{
	// 	// 	transform: scale(.9);
	// 	// }
	// }
	//
	// @include break-max($break-sm) {
	// 	grid-template-columns: repeat(1, 1fr) !important;
	// 	// grid-template-rows: repeat(2, 1fr) !important;
	// 	// .provider-logo{
	// 	// 	transform: scale(.8);
	// 	// }
	// }
}

.resize-panels{
	background: gray;
	// flex-grow: 3;
	display: flex;
	flex-flow: row nowrap;
	&.full{
		background: $color-panel-main;
		// flex-wrap: wrap;
		padding: 0;
		width: 100%;
		justify-content: space-between;
		// height: 100%;
		height: calc( 100vh - 44px );
	}

	.content{
	  width: 100%;
	  height: 100vh;
	  display: flex;
	  flex-flow: nowrap column;
	  overflow:hidden;
	}
	.panel{

		&.full{
			background: $color-panel-sub-darker;
			background: rgb(14 14 14 / 53%);
			display: flex;
			flex-direction: column;
			// border-radius: $radius-md;
			// box-shadow: $shadow-panel-sub;

			// max-width: 320px;
			// height: 370px;
			// margin: 9px;
			overflow: hidden;
		}
	}


	.customHandle {
	  cursor: ew-resize;
	  width: 10px;
	  height: 100px;
	  margin: 0px -5px;
	  background: lightblue;
	  border: 2px solid gray;
	  border-radius: 2px;
	  text-align: center;
	  z-index: 99999;
	  overflow: hidden;
	  display: flex;
	  align-items: center ;
	}

	.customResizeBorder {
	  cursor: ew-resize;
	  width: 5px;
	  background: gray;
	  display: flex;
	  z-index: 99999;
	  align-items: center ;
	  align-content: center ;
	  justify-content: center;
	  overflow: visible;

	}
}

.video-responsive{
	width: 84%;
	max-width: 444px;
	aspect-ratio: 1.7777;
}

.item-border{
	border: $item-border !important;
}
.tx-chart{
	display: flex;
	position: relative;
	width: 100%;
	height:3px;
	background-color: $color-grey-darkest;
	border-radius: 2px;
	overflow: hidden;
	.tx-buys,.tx-sells{
		height: 3px;
	}
	.tx-buys{
		background-color: $color-green;
	}
	.tx-sells{
		background-color: $color-red;
	}
}

.meta-tag-primary{
	position: absolute;
    top: 0;
    right: 0;
	&.left{
		right:auto;
		left:0;
	}
    font-size: 11px;
    padding: 0 4px;
	background-color: $color-panel-dark;
}

.feature-card-tokens{
	display: flex;
	flex-wrap: wrap;
}


       
.feature-card-token{

	.feature-card-token-header{
		background-color: $color-panel-data;
		background-color: rgba(33,39,51,.55);
		// box-shadow: $shadow-panel-sub;
		border-top-left-radius: $radius-sm;
		border-top-right-radius: $radius-sm;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		max-height: 22px;
		padding:2px;
	}
	
	.feature-card-token-footer{
		background-color: rgba(33,39,51,.55);
		box-shadow: $shadow-panel-sub;
		border-bottom-left-radius: $radius-sm;
		border-bottom-right-radius: $radius-sm;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height:11px;
	}

	
	// max-width: 199px;
	height:153px;


	@include break-max($break-xxs) {
		width: 100%;
	}

	@include break-min($break-xs) {
		width: 244px;
	}

	@include break-max($break-xxs) {
	// 	width: 95%;
	}

	&.trending{
		padding-top:31px;
		height: 181px;	
	}
	background-color: $color-panel-data;
	box-shadow: $shadow-panel-sub-light;
	border-radius: $radius-sm;
	overflow: hidden;
	margin-right: 9px;
	margin-top: 9px;
	position: relative;
	display:block;
	padding:3px 0;
	padding-top: 7px;
	padding-left: 4px;
}

.feature-card{
	width: 80%;
	max-width: 480px;

	@include break-max($break-sm) {
		width: 100%;
		max-width: 100%;
	}

	
	box-shadow: $shadow-panel-sub;
	box-shadow: 0px 0px 1px 1px rgba(53, 53, 53, 0.4);
	border-radius: $radius-md;
	overflow: hidden;
	.feature-card-title{
		position: relative;
		width: 100%;
		min-height:33px;
		background: $color-panel-data;
		// padding: 4px 7px;
		border-top-left-radius: $radius-md;
		border-top-right-radius: $radius-md;
		overflow: hidden;
		// min-height: 57px;
		p{
			margin:0;
			// padding:4px;
			// z-index: 44;
			font-size: 12px;
			
		}

		.text-content{
			width: calc( 100% - 4px );
			margin:3px 4px;
			padding:4px;
			z-index: 44;
			// display: flex;
			// align-items: center;
			// min-height: 84px;
			// position: absolute;
		}
	}
	.feature-card-footer{
		background: #2a2a2aed;
		padding: 4px 7px;
		border-bottom-left-radius: $radius-md;
		border-bottom-right-radius: $radius-md;
	}
	.feature-sponsor{
		line-height: inherit;
		.sponsor-logo{
			width: 100%;
			position: absolute;
			z-index: 99;
			// margin: 7px;
			display: flex;
			justify-content: center;
		}
		.sponsor-caption{
			position: absolute;
			z-index: 999;
			margin: 15px;
			top: 106px;
			font-size: 12px;
		}
	}
	.alice-carousel__wrapper{
		// border-radius: $radius-md;
		overflow: hidden;
		background-color: $color-background-dark;
		// box-shadow: $shadow-panel-sub;
	}
	img{
		max-width: 100% !important;
	}

	&.code{
		margin-top: 7px;
		color: rgb(1, 248, 5);
		box-shadow: none;
		background-color: #111111;
	}
}

.alice-carousel{
	// border-radius: $radius-md;
	overflow: hidden;
}

.alice-carousel__dots-item.__active, .alice-carousel__dots-item:hover{
	background-color: $color-brand !important;
}
.alice-carousel__dots-item:not(.__custom){
	background-color: $color-button-neutral;
}


.alice-carousel__dots {
    margin: 12px 3px 5px;
	li{
		&:not(.__custom):not(:last-child) {
			margin-right: 13px !important;
		}
	}
}

.alice-carousel__prev-btn{
	text-align: left;
	position: absolute;
    top: 177px;
}
.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item{
	background-color: $color-panel-data;
	border-radius: $radius-md;
	overflow: hidden;
	font-weight: bold;
	font-size: 15px;
	&:hover{
		color: $color-brand;
	}
}
.alice-carousel__next-btn{
	text-align: right;
	position: absolute;
    top: 177px;
	right: 0;
}
.alice-carousel__next-btn-item{

}

.token-list-item-symbol{
	width:27px;
	aspect-ratio: 1;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	img{
		max-width: 100%;
	}
	margin-left:3px;
	&.interactive{
		cursor: pointer;
		&:hover{
			transform: scale(1.2);
		}
	}
	&.overlap{
		margin-left: -7px;
	}
	
	border: 2px solid #1d1d1d;
	background-color: #1d1d1d;
}

.meme-photo{
	// max-width: 270px;
    padding: 7px;
    background-color: #191919e0;
    border-radius: 7px;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
	box-sizing: border-box;
	
	img{
		display: block;
		height: auto;
		width:100%;
		max-width: 250px;
		// max-width: 100%;
		border-radius: 4px;
		box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.4);
		box-shadow: 0px 0px 1px 1px rgba(53, 53, 53, 0.4);
	}
}

.feature-panel{
	
	min-height: 44px;
	
	.grid-container {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(333px, 1fr)); /* Two columns with a minimum width of 200px */
		gap: 4px; /* Gap between items */
		grid-auto-flow: dense; /* Allow */
	}
	
	.grid-item {
		background-color: #f2f2f2;
		padding: 7px;
	}

	&.full{
		width: 100%;
		.feature-content{
			width: 100%;
		}
	}

	// max-width: 640px;
	// background: $color-panel-sub-darker;
	// background: rgb(14 14 14 / 53%);
	display: flex;
	flex: 1;
	flex-direction: column;
	// border-radius: $radius-md;
	// box-shadow: $shadow-panel-sub;

	// max-width: 320px;
	// height: 370px;
	// margin: 9px;
	// overflow: hidden;
	position: relative;

	.news-item{
		p{
			a{
				display: none;
			}
		}
	}

	.call-item, .news-item{
		// margin: 4px !important;
		max-width: 720px;
		width: 100%;

		.call-item-photo{
			margin-top:11px;
			margin-left:11px;
			width: 80%;
			cursor: pointer;
			img{
				max-width: 100%;
				border-radius: 7px;
			}
		}
		.video-responsive{
			margin:11px;
		}
		.web-page{
			
			// margin-left: 11px;
			margin-top:7px;
			margin-bottom:7px;
			border-left:1px solid $color-brand-opaque;
			padding-left:11px;
		}
	}

	.list-item{
		position: relative;
		width: 80%;
		max-width: 444px;
		margin-right: 7px;
		margin-bottom: 7px;
		@include break-max($break-sm) {
			width: 100%;
			max-width: 100%;
		}
	}
	.news-item, .call-item, .list-item{
		h6{
			margin: 0;
		}
		min-width: 320px;
		flex-direction: column;
		// margin: 2px 7px !important;
	    padding: 11px 6px 8px 8px;
	    // margin: 4px 0px;
	    // border-radius: $radius-md;
	    background: $color-panel-sub-opaque;
		position: relative;
		border: $item-border;
		box-shadow: 0px 0px 1px 1px rgba(53, 53, 53, 0.4);
		// max-width: 720px;
		.web-link{
			position: absolute;
			right: 7px;
			bottom: 7px;
			.svg-icon{
				transform: scale(.64);
				opacity: .5;
			}
			
		}
		.item-header{
	        display: flex;
	        flex-direction: row;
	        align-items: center;
			justify-content: space-between;
			padding: 0 12px 0 5px;
			p{
				font-size: 12px;
				font-weight: bold;
			}
	    }
	    .profile-image{
	        width:33px;
	        height:33px;
	        // border-radius: 50%;
	        // box-shadow: $shadow-panel-sub;
	    }
		&.call-item{
			.profile-image.main{
		        // width:33px;
		        height:33px;
		        border-radius: 50%;
				// border: 2px solid #ffffff33;
		        box-shadow: $shadow-panel-sub;
		    }
		}
		p{
			margin: 0;
			font-size: 16px;
			color: #c3d7e1;
			// white-space: nowrap;
			// text-overflow: ellipsis;
			word-break: break-word;
			// overflow: hidden;
			width: 84%;
			max-width: 555px;
		}
		
	}

	.feature-title{
		// backdrop-filter: blur(5px);
		position: fixed;
		z-index: 122;
		width: 100%;
		height: 44px;
		display: flex;
		background-color: $color-panel-header-light;
		align-items: center;
		// padding:7px;
		// padding: 20px 7px;
		z-index: 999;
		
		*{
			margin:0;
		}
		p{
			font-weight: bold;
		}
	}

	&.opaque-title{
		.feature-title{
			position: absolute;
			-webkit-backdrop-filter: blur(3px);
			backdrop-filter: blur(3px);
		}
		.feature-content{
			padding-top:36px;
		}
	}

	.feature-content{
		// padding: 7px;
		flex:1;
		position: relative;
		overflow-y: auto;
		min-height:400px;

		// padding-bottom: 444px !important;

		> div{
			// padding: 2px;
		}

		&.wrap-content{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
	
		a{
			text-decoration: underline;
			// color: $color-link;
			opacity: .8;
    		font-size: 15px;
			&:hover, &.active{
				color: $color-text-active;
			}
			
		}
		a.full{
			width: 100% !important;
			display: flex;
			background: $color-panel-data;
			padding: 7px;
			margin: 1px;
			text-decoration: none;
			&:hover, &.active{
				background: $color-panel-sub-item-dark;
			}
		}
		// background: $color-panel-sub-darker;
	}

}

.radar-container {
	pointer-events: auto; /* Ensure interactions are not blocked */
	overflow: visible; /* Ensure overflow doesn't block zooming */
	width:100%;
	height: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	canvas {
		touch-action: pan-y !important;
		pointer-events: auto; /* Allow mouse events on the canvas */
	}
}

  

.feature-table-container{
	
	background: #040404b8;	
	display: flex;
	justify-content: center;
	
	width: 100%;
	max-width: 777px;
	align-items: center;
	justify-content: center;
	border-radius: 7px;
	overflow: hidden;
	box-shadow: $shadow-panel-sub-light;

	@include break-max($break-sm) {
        *{
			font-size: 11px !important;
		}
        
    
    }

	@include break-max($break-xs) {
        *{
			font-size: 8px !important;
		}
        
    
    }

	.feature-table {
		width: 100%;
		// max-width: 600px;
		border-collapse: collapse;
		text-align: center;
	}

	.feature-table th,
	.feature-table td {
		padding: 12px;
		text-align: left;
	}

	.feature-table th{
		font-size: 18px;
		max-width: 180px;
		&:not(:last-child){
			border-right: 1px solid #2222228f;
		}
	}

	.feature-table tr{
		border-top: 1px solid #2222228f;

		td:first-child{
			background-color: #262e3562;
		}
		td:nth-child(2),th:nth-child(2){
			background-color: #7d2bb734;
		}
		td:nth-child(3),th:nth-child(3){
			background-color: #2b81b734;
		}
		td:nth-child(4),th:nth-child(4){
			background-color: rgb(14 245 11 / 11%);
		}
	}

	.feature-table td {
		font-weight: 600;
		padding: 8px;
		font-size: 13px;
		&:not(:last-child){
			border-right: 1px solid #2222228f;
		}
		
	}

	.feature-table th {
		font-weight: bold;
		background-color: #262e3532;
		vertical-align: top;
	}

	.feature-table tbody tr:nth-child(odd) {
		// background-color: #5e4141;
	}

	.feature-table tbody tr:nth-child(even) {
		// background-color: #ffffff;
	}
}

// .feature-panel:nth-child(even) {
// 	// background: $color-panel-sub-item;

// 	.feature-title{
// 		background-color: $color-panel-sub-opaque;
// 	}
// 	// background: $color-panel-sub-item-dark !important;
// 	background: $color-panel-main !important;
// }

.feature-title, .trending-nav-dex{
	background: linear-gradient(to bottom, #2b2b2b 0%, #333 60%, #222222 100%);
	background: linear-gradient(to bottom, #1e1e1e 0%, #252424 60%, #1a1a1a 100%);
}
.list-title, .chain-item{
	background: linear-gradient(rgba(31, 34, 37,.77), rgba(21, 24, 27, .77));
	// background: linear-gradient(rgb(20 24 26 / 76%), rgb(16 20 23 / 59%));
}

.list-title{
    position: relative;
	// backdrop-filter: blur(5px);
	position: relative;
	z-index: 122;
	width: 100%;
	height: 33px;
	display: flex;
	// background-color: $color-panel-header-lighter;
	
	align-items: center;
	padding:7px;
	// top:44px;
	padding: 20px 7px;
	z-index: 999;
	
	border-bottom: 1px solid #1b1b1b;
	border-top: 1px solid #242424;
	*{
		margin:0;
	}
	p{
		font-weight: bold;
	}
}

.token-symbol-icon{
	.data-icon{
		margin-right: 0;
		img{
			max-width: 48px;
			// height: 39px;
		}
	}
	&.minimal{
		margin-right: 0;
		.data-icon{
			margin-right: 0;
			min-width: 0 !important;
			img{
				width:24px;
				height:24px;
			}
		}
	}

	&.large{
		.data-icon{
			img{
				max-width: 59px;
				margin-right: 7px;
				// height: 53px;
			}
		}
	}
}

.filters-panel{
	background: rgb(17 24 28 / 93%);
	// backdrop-filter: blur(12px);
	position: absolute;
    width: 100%;
    margin-top: 44px;
    // min-height: 333px;
	max-width: 555px;

    z-index: 999;
	padding: 27px;
	padding-bottom: 88px;
	border-radius: 0 0 $radius-md $radius-md;
	.flex-row{
		width:60%;
		max-width: 555px;
	}
	.input-switch-group{
		width: 77%;
		background: #161616;
    	padding: 20px 20px 44px 20px;
		border-radius: $radius-md;
		margin: 10px 0;
	}
	input{
		max-width: 120px;
	}
	.no-margin{
		.input-group{
			margin: 0;
			padding: 0;
		}
	}
	*{
		text-align: left;
	}

}

.rank-crown{
	position: absolute;
	top: -8px;
	left: 4px;
	width: 13px;
	height: 12px;

	svg{
		width: 13px;
		height: 12px;
		*{
			
			fill: #f2b806;
			stroke: #f2b806;
		}
		
	}
}
.rank-number{
	position: relative;
	font-weight: bold;
	font-size: 11px;
	// padding:2px;
	border-radius: $radius-md;
	border: 2px solid #013f5b;
    background: #002741;
	color: #579fc3;
    width: 18px;
    height: 18px;
    // align-items: center;
    justify-content: center;
    display: flex;
	// line-height: 16px;
	// line-height: 18px;
    min-width: 21px;
    min-height: 21px;
	&.rank-1{
		background: #d25601;
		border-color: #ce8105;
		color: #f2b806;
	}
	&.rank-2{
		background: #484848;
		border-color: #888888;
		color: #e2e2e2;
	}
	&.rank-3{
		background: #803618;
		border-color: #a45631;
		color: #fca061;
	}
}



//
// .dex-row{
// 	// background: $color-panel-sub-item;
// 	cursor: pointer;
// 	td{
// 		background: $color-panel-sub-item;
// 		border-radius: 2px;
// 		overflow: hidden;
// 		padding:7px;
//
// 	}
//
// 	&:hover{
// 		td{
// 			background: $color-panel-sub-item-roll;
// 		}
// 	}
//
// 	padding:7px;
//
// }
