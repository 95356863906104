/*******************************************
Form Fields and Buttons
*******************************************/

// Buttons
.button,
button,
input[type="button"],
input[type="submit"] {
    box-shadow: 0 12px 15px -8px rgba(0, 0, 0, 0.15);
    // background-color: $color-panel-button;
    background-color: rgba(0,105,172,1);
    
    border: 1px solid transparent;
    border-radius: $radius-lg;
    color: $color-text-button;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: $semi-bold;
    line-height: normal;
    outline: none;
    margin: 20px 0;
    padding: 9px 16px;
    text-align: center;
    white-space: nowrap;
    opacity: 1;
    transition: background-color $hover-duration, opacity $hover-duration;

    // States

    &:hover, &.active {
        // background-color: darken($color-panel-button-roll, 11%);
        // background-color: $color-panel-button-roll;
        background-color: rgba(0, 157, 255, 0.83);
        text-decoration: none;


        // &.dark-icon{
            .svg-icon{
                svg{
                    fill: $color-grey-darker !important;
                    stroke: $color-grey-darker !important;
                    *{
                        fill: $color-grey-darker !important;
                        stroke: $color-grey-darker !important;
                    }
                }
                img{
                    filter: grayscale(100%) brightness(20%) !important;
                }
            }
        // }
        
    }

    &.blank{
        background: none !important;
        padding: 0 !important;
        margin: 0 !important;
        p{
            color: $color-text;
        }
    }

    &.panel{
        color: $color-text;
        background-color: $color-panel-button !important;
        &:hover{
            background-color: $color-panel-button-roll !important;
        }
    }

    // Color Options

    &.transparent {
        box-shadow: none;
        background-color: transparent;
        color: $color-text-medium;

        &.white {
            color: white;

            &:hover {
                text-decoration: underline;
            }
        }
        &:hover {
            color: $color-text;
        }
    }

    &.slim{
        padding: 6px 16px;
    }

    &.tight-margin{
        margin: 7px 0;
    }

    &.outline {
        background-color: rgb(39 39 39 / 73%);
        box-shadow: none;
        border: 1px solid $color-button-action;
        border: 1px solid #2d4153;
        color: $color-text-medium;
        transition: background-color $input-duration;
        max-height: 38px;
        &:hover, &.active {
            background-color: darken(white, 5%);
            background-color: $color-button-action;
            color: $color-off-white;
            color: $color-grey-dark;
            img,svg{
                // filter: brightness(0%);
            }
        }
    }

    &.filter{
        background-color: $color-panel-button;
        color: $color-text-medium;
        margin-right: 5px;
        &:hover, &.active{
            background-color: $color-panel-button-roll;
            color: $color-button-action;
        }
    }

    &.action {
        background-color: $color-button-action;
        transform: scale(1);
        transition:
            background-color $hover-duration,
            transform $hover-duration,
            opacity $hover-duration;

        &:hover {
            background-color: $color-background-dark;
            color: $color-text;
            // background-color: $color-button-action-roll;
            // transform: scale(1.02);
        }
    }

   
    svg,img{
      
        width: 27px;
        height: auto !important;
        margin-right: 10px;
    }

    .no-margin{
        svg,img{
            margin-right: 0 !important;
        }
    }

    &.positive {
        background-color: $color-button-positive;

        &:hover {
            background-color: lighten($color-button-positive, 5%);
        }
    }

    &.white {
        background-color: white;
        box-shadow: none;
        color: $color-text-medium;

        &:hover {
            background-color: lighten($color-blue, 15%);
        }
    }

    &.neutral {
        background-color: $color-grey-dark;
        color: $color-text;
        box-shadow: none;

        &:hover {
            background-color: darken($color-grey-dark, 15%);
        }
    }

    &.breadcrumb{
        background-color: $color-grey-dark;
        color: $color-text;
        transition: background-color $input-duration;
        font-size: 10px;
        font-weight: 600;
        margin-left: 5px;
        padding: 5px 8px;


        border-radius: 4px;
        line-height: 13px;
        &:first-child{
            margin-left: 0;
        }
        i{
            font-size: 14px;
        }
        &:hover, &.active{
            background-color: $color-grey-darker;
            color: $color-brand;
            i{
                color: $color-brand;
            }
        }
        &.active{
            cursor: default;
        }
    }

    &.link {
        color: $color-nav-active;
    }

    &.danger:not(.transparent):not(.outline) {
        color: white !important;
        background-color: $color-danger;
        transition: transform $hover-duration;

        &:hover {
            transform: scale(1.02);
        }
    }

    &.no-shadow {
        box-shadow: none;
    }

    // Default Spinner

    .spinner {
        display: block;
        height: 18px;
        width: auto;
        margin-left: 6px;
    }

    // Size options

    &.tiny{
        border-radius: 3px;
        font-size: 10px;
        padding: 4px 6px;
        .spinner {
            height: 12px;
        }
    }

    &.xsmall {
        border-radius: $radius-sm;
        font-size: 11px;
        padding: 3px 8px;

        .spinner {
            height: 14px;
        }
    }

    &.small {
        border-radius: $radius-md;
        font-size: 12px;
        padding: 6px 12px;

        .spinner {
            height: 15px;
        }
    }

    &.default {
        // same as base styles
    }

    &.large {
        border-radius: $radius-lg + 2;
        font-size: 16px;
        padding: 17px 22px;

        .spinner {
            height: 19px;
        }
    }

    &.round {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0 !important;
        width: 32px;
        height: 32px;
        transform: scale(1);
        transition: $hover-duration transform;

        &:hover {
            transform: scale(1.02);
        }

        > i {
            font-size: 20px;
        }

        &.small {
            width: 24px;
            height: 24px;

            > i {
                font-size: 16px;
            }
        }

        &.default {
            // same as base styles
        }

        &.large {
            width: 38px;
            height: 38px;

            > i {
                font-size: 24px;
            }
        }
    }

    &.disabled,
    &[disabled] {
        opacity: 0.5;
        filter: saturate(0);
        pointer-events: none;
    }

    &.inline {
        display: inline-flex;
        margin-top: 0;
    }

    // Style a loading spinner if it appears next to the button

    + .spinner {
        margin-left: 6px;
        height: 20px;
        width: auto;
        opacity: 0.5;
    }
}

.inline-buttons {
    display: inline-flex;
    justify-content: space-between;
    margin: 20px 0;

    .button,
    > button {
        margin: 0 10px 0 0;
    }
}

// Fields

.field, // Only need to use .field when emulating a field.
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
input[type="password"],
select,
textarea:not(.code-input),
.textarea:not(.code-input),
code:not(.prism) {
    background: $color-field-default;
    border: 1px solid transparent;
    border-radius: $radius-md;
    color: $color-text;
    display: block;
    font-size: 13px;
    line-height: normal;
    outline: none;
    padding: 7px 11px;
    word-wrap: break-word;
    position: relative;
    margin: 10px 0;
    width: 100%;
    opacity: 1;
    //z-index: 1;
    transition: border-color $input-duration, opacity $input-duration;

    + label {
        transition: color $input-duration;
    }

    // States

    &:focus {
        border-color: $color-field-active;
    }

    &.active {
        border-color: $color-field-active !important;
    }

    // Autofill

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        background: $color-field-default !important;
        // border: 1px solid transparent;
        -webkit-text-fill-color: $color-text;
        -webkit-box-shadow: 0 0 0px 1000px $color-field-default inset !important;
    }

    // Placeholder styles

    &::-webkit-input-placeholder {
        // Chrome/Opera/Safari
        color: $color-placeholder;
    }
    &::-moz-placeholder {
        // Firefox 19+
        color: $color-placeholder;
        opacity: 1; // Make color same in Firefox
    }
    &:-ms-input-placeholder {
        // IE 10+
        color: $color-placeholder;
    }
    &:-moz-placeholder {
        // Firefox 18-
        color: $color-placeholder;
        opacity: 1; // Make color same in Firefox
    }

    // Options



    &.xsmall {
        font-size: 11px;
        padding: 5px 12px;
    }

    &.small {
        font-size: 12px;
        padding: 10px 16px;
    }

    &.large {
        font-size: 14px;
        padding: 15px 20px;

        @include break-max($break-xs) {
            font-size: 16px;
        }
    }

    &.dark {
        background: $color-field-dark;
        color: white;

        // &:-webkit-autofill,
        // &:-webkit-autofill:hover,
        // &:-webkit-autofill:focus {
        //     background: $color-field-dark !important;
        //     -webkit-text-fill-color: white;
        //     -webkit-box-shadow: 0 0 0px 1000px $color-field-dark inset !important;
        // }

        &::-webkit-input-placeholder {
            // Chrome/Opera/Safari
            color: $color-placeholder-dark;
        }
        &::-moz-placeholder {
            // Firefox 19+
            color: $color-placeholder-dark;
            opacity: 1; // Make color same in Firefox
        }
        &:-ms-input-placeholder {
            // IE 10+
            color: $color-placeholder-dark;
        }
        &:-moz-placeholder {
            // Firefox 18-
            color: $color-placeholder-dark;
            opacity: 1; // Make color same in Firefox
        }
    }

    &.outline {
        background: transparent;
        background-color: $color-panel-button;
        background-color: rgb(13 13 13 / 73%);
        border: 1px solid $color-border;

        &.outline-danger{
            border-color: $color-danger !important;
        }
        &.outline-valid{
            border-color: $color-green !important;
        }
        &:hover{
            background-color: $color-panel-button-roll;
        }
        &:focus {
            border-color: $color-field-active;
        }
    }

    &.disabled,
    &[disabled] {
        opacity: 0.5 !important;

        &.outline {
            background: rgba(white, 0.5);
            background: rgb(55 55 55 / 50%);
            color: $color-text-light;
            opacity: 1;
        }

        pointer-events: none;
    }

    &.font {
        height: 40px;
    }

    &.mono {
        @extend %mono;
    }
}

.select {
    position: relative;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        position: relative;
        z-index: 1;
        cursor: pointer;
        padding-right: 35px;
    }

    &::after {
        @include icons();
        content: "\e900";
        font-size: 20px;
        color: inherit;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        pointer-events: none;
    }
}

textarea:not(.code-input),
.textarea:not(.code-input) {
    min-height: 108px;
    line-height: 24px;
    padding: 15px 20px;
}

.code:not(.prism),
code:not(.prism) {
    line-height: 24px;
    padding: 11px 20px;
    white-space: pre-line;
    word-break: break-word;
    max-height: 200px;
    overflow: auto;
    @extend %mono;
}

input[type="text"].default {
    background: white;
    border-radius: $radius-sm;
    border: none;
    font-size: 14px;
    padding: 6px 10px;
}

// Custom Dropdown

.dropdown {
    position: relative;
    z-index: 3;

    &::after {
        @include icons();
        content: "\e900";
        font-size: 20px;
        color: inherit;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        pointer-events: none;
    }

    input[type="text"] {
        cursor: pointer;
        position: relative;
    }

    .dropdown-options {
        // border: 1px solid $color-border;
        box-shadow: 0 18px 35px -5px rgba(0, 0, 0, 0.1),
            0 0 1px 1px rgba(0, 0, 0, 0.05);
        background: #222222;
        border-radius: $radius-md;
        display: none;
        position: absolute;
        top: calc(100% + 2px);
        width: 100%;
        // z-index: 2;
        overflow: auto;
        max-height: 400px;

        .option {
            font-size: 18px;
            color: $color-text;
            cursor: pointer;
            padding: 8px 16px;

            &:hover{
                background: #1b1b1b;
            }
        }

        &.active {
            display: block;
        }
    }
}

// Groupings of fields in a single container (Also used when including a label for a field)
.input-group-row{
    display:flex;
    flex-direction: row;
    .input-group{
        // width:33%;
    }
}

.input-group {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 8px 0;
    padding: 0 15px;
    &.disabled{
        filter: saturate(0) brightness(.5);
        pointer-events: none;
    }
    
    p{
        margin: 0;
        font-weight: 600;
        font-size: 12px;
    }

    &.no-margin{
        margin:0;
        padding: 0;
    }

    &.inline {
        display: inline-flex;
        flex-direction: row;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="url"],
    input[type="search"],
    input[type="password"],
    select,
    textarea,
    code,
    .code,
    .dropdown,
    .input-combo,
    .select,
    .textarea,
    .input-group-combined,
    .color-picker-wrapper,
    .react-datepicker-wrapper {
        order: 2;
        margin: 0;
    }

    > label {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        // order: 1;

        + .switch {
            margin-left: 10px;
        }
    }

    .error {
        order: 3;
        margin: 8px 0 0 2px;
        font-size: 13px;

        &:empty {
            margin: 0;
        }
    }

    + .error {
        margin-top: 8px;
    }

    .switch {
        order: 2;
    }

    .switch.fixed {
        position: absolute;
        top: 0;
        right: 0;
    }

    &.inset {
        border-left: 1px solid $color-border;
        padding-left: 14px;
    }
}

// Multiple input groups combined in a row

.input-group-combined {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: -5px !important;
    margin-right: -5px !important;

    > button,
    > input[type="text"],
    .input-group,
    .input-group-combined {
        flex: 1 1 0;
        margin: 0 5px;
    }

    &.inline {
        display: inline-flex;
        margin-top: 0;
    }
}

// Switch with field

.input-switch-group {
    margin: 20px 0;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    > label {
        margin-bottom: 10px;
        // order: 1;
    }

    .switch-wrapper {
        margin-bottom: 10px;

        > label {
            @extend %meta;
        }

        + .input-group {
            margin-top: 10px;
        }
    }
}

// Combination of field and button

.input-combo {
    position: relative;
    margin: 20px 0;

    input[type="text"] {
        margin: 0;
    }

    &.over {
        button,
        input[type="submit"] {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            margin: 0;
        }

        > input:not([type="submit"]),
        > code {
            position: relative;
            z-index: 1;
            margin: 0;
            padding-right: 100px;
        }

        > code + button {
            right: 20px;
        }
    }

    &.inline {
        display: flex;
        align-items: center;

        button,
        input[type="submit"] {
            margin: 0;
        }

        > input:not([type="submit"]),
        > code {
            flex: 1;
        }
    }

    &.icon {
        input[type="text"] {
            padding-right: 44px;
        }

        i {
            color: $color-text;
            cursor: pointer;
            font-size: 20px;
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            pointer-events: none;
        }
    }

    p.copied {
        text-align: right;
        position: absolute;
        top: calc(100% + 5px);
        right: 5px;
    }
}

// Input list (e.g. Invite Participants)

.input-list {
    margin: 20px 0;

    .input-group {
        margin-bottom: 12px;
    }
}

// On/Off Switch

.switch-wrapper {
    flex-direction: row;
    justify-content: space-between;

    > label {
        font-size: 16px;
        line-height: 24px;
        color: $color-text;
        margin: 0;
    }

    + .meta {
        display: block;
        margin-top: -20px;
        padding-right: 60px;
    }

    + .input-group.inset,
    + .meta + .input-group.inset {
        margin-top: 20px;
    }
}

input[type="checkbox"]:checked {
    background-color: $color-button-action;
}

input[type="checkbox"]:checked + label {
    background-color: blue;
    /* Apply this to a label or any other element that comes after the checkbox */
}

.switch {
    width: 44px;
    height: 24px;
    position: relative;

    input[type="checkbox"] {
        cursor: pointer;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 3;
    }

    span {
        display: block;
    }

    input + span {
        box-shadow: 0 1px 3px 0 rgba(0,0,0,0.20);
        background-color: #ACB5BF;
        border-radius: 50%;
        display: block;
        position: absolute;
        z-index: 2;
        width: 24px;
        height: 24px;
        top: 0;
        left: 0;
        transition: left $input-duration, background-color $input-duration;

        + span {
            background-color: transparent;
            border: 1px solid $color-border;
            border-radius: 10px;
            width: 40px;
            height: 20px;
            position: relative;
            left: 2px;
            top: 2px;
            transition: border-color $input-duration, background-color $input-duration;
        }
    }

    input[type="checkbox"]:checked {
        + span {
            background: white;
            left: calc(100% - 24px);

            + span {
                background-color: $color-blue;
                border-color: $color-blue;
            }
        }
    }
}

.slider{
    width: 100%;
}

// Checkbox / Radio

.checkbox-wrapper {
    position: relative;
    flex-direction: row;
}

input[type="radio"]:focus ~ span:before {
  outline: none;
  box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.4);
}

.checkbox,
.radio {
    width: 24px;
    height: 24px;
    position: relative;

    > input,
    > span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    > span {
        display: block;
        background: rgba(white, 0.25);
        border: 1px solid #c2c2c2;
        border-radius: $radius-sm;
        visibility: hidden;
        pointer-events: none;

        > i {
            display: block;
            font-size: 22px;
            line-height: 22px;
            width: 22px;
            color: white;
        }
    }

    > input[type="checkbox"],
    > input[type="radio"] {
        display: block;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
        order: 1;

        + span {
            visibility: visible;
        }

        &:checked {
            + span {
                visibility: hidden;
            }

            + span + span {
                visibility: visible;
                border-color: transparent;
                background: $color-blue;
            }
        }

        &:focus:not(:active) {
            + span {
                border-color: $color-field-active;
            }
        }
    }

    + label {
        order: 2;
        margin: 0 0 0 12px;
        line-height: 22px;
        cursor: pointer;
    }
}

.radio > span {
    border-radius: 50%;
}

// Color Picker

.color-picker-wrapper {
    display: flex;
    align-items: center;

    .swatch-preview {
        box-shadow: 0 1px 4px 0 rgba(0,0,0,0.07);
        border-radius: $radius-md;
        cursor: pointer;
        width: 38px;
        height: 38px;
        margin-right: 5px;
        flex: 0 0 38px;
    }

    input[type="text"] {
        flex: 1;
        margin: 0;
        font-size: 16px;
        text-transform: uppercase;
    }
}

.color-picker {
    position: absolute;
    top: calc(100% + 5px);
    z-index: 111;
}

.input-group:last-child{
    .color-picker{
        right:0;
    }
    &.input-group:first-child{
        .color-picker{
            left:0;
            right: initial;
        }
    }
}

// Segments

.segments {
    display: inline-flex;
    align-items: center;
    background-color: #444;
    border-radius: $radius-lg;
    padding: 2px;

    > button,
    > .button {
        margin: 0;
        flex: 1;
        white-space: nowrap;
        margin: 1px;
        &:hover{
            background: #373737;
        }
    }


    .active {
        background: $color-grey-dark;
        box-shadow: 0 1px 4px 0 rgba(0,0,0,0.13);
        color: $color-text-light !important;
        &:hover{
            background: $color-grey-dark;
        }
    }
}

// File Upload

.file-upload {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .button {
        margin: 0;
    }

    .file-upload-hidden {
        width: 0;
        height: 0;
        visibility: hidden;
        position: absolute;
    }
}

// Form actions container

.form-actions {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0 0;
    border-top: 1px solid $color-border;

    > button,
    input[type="submit"] {
        margin-left: 10px;
    }
}

.profile-photo{
    width:120px;
    height:120px;
    img{
        max-width: 100%;
        height:100%;
        border-radius: 50%;
    }
    position: relative;

    .camera-button{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -22px;
        margin-top: -22px;
        width:44px;
        height:44px;
        border-radius:22px;
        background: rgba(0,0,0,.4);
        display:flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover{
            background: $color-panel-data;
        }
    }
}


/*Range Reset*/
input[type="range"] {
    -webkit-appearance: none;
     appearance: none;
     background: transparent;
     cursor: pointer;
     width: 15rem;
     max-width: 220px;
 }
 
 /* Removes default focus */
 input[type="range"]:focus {
   outline: none;
 }
 
 /***** Chrome, Safari, Opera and Edge Chromium styles *****/
 /* slider track */
 input[type="range"]::-webkit-slider-runnable-track {
    background-color: #3b5364;
    border-radius: 0.5rem;
    height: 0.5rem;  
 }
 
 /* slider thumb */
 input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -4px; /* Centers thumb on the track */
    border-radius: 50%;
    /*custom styles*/
    background-color: $color-button-action;
    height: 1rem;
    width: 1rem;
 }
 
 
 
 /******** Firefox styles ********/
 /* slider track */
 input[type="range"]::-moz-range-track {
    background-color: #3b5364;
    border-radius: 0.5rem;
    height: 0.5rem;
 }
 
 /* slider thumb */
 input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    // border-radius: 0; /*Removes default border-radius that FF applies*/
    border-radius: 50%;
    /*custom styles*/
    background-color: $color-button-action;
    height: 1rem;
    width: 1rem;
 }
 

.editor-container {
    display: flex;
    position: relative;
    // background-color: #f5f5f5;
    border-radius: 5px;
    // border: 1px solid #ddd;
    width:100%;
}

.line-numbers {
    padding: 10px;
    background-color: rgba(11,11,11,.3);
    color: #999;
    text-align: right;
    font-family: "Fira code", "Fira Mono", monospace;
    font-size: 14px;
    user-select: none;
    // border-right: 1px solid #ddd;
}

.line-numbers pre {
    margin: 0;
    line-height: 1.17;
}