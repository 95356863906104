/*******************************************
Social Styles
*******************************************/


.social-dashboard{
    background: #08080880;
    height: 100%;
    display: none;
    &.visible{
        display: block;
    }
}

.social-list{
    display: flex;
    // width: 100%;
    height: 100%;
}

.social-content{
    display: flex;
    max-width: 100%;
    overflow-x: auto;
}


.social-header{
    background: rgba(14,16,18,.64);
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 999;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(2px);
    // box-shadow: $shadow-tight;
    padding: 7px 15px;
    padding-top: 9px;

    justify-content: space-between;
    position: absolute;
    // width: calc( 100% - 7px );
    width: 100%;

    .input-group{
        margin: 0 !important;
    }

    .svg-icon{
        svg{
            fill: $color-brand;
        }
        img{
            max-width: 100%;
        }
    }
}
.user-avatar{
    width: 27px;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    img{
        max-width: 100%;
    }
}

.social-panel{
    width: 100%;
    height: calc( 100vh - 44px );
    // background: #333;
    // background: $color-panel-main;
    padding: 73px 7px 24px 7px;
    overflow-x: hidden;

    align-items: flex-start;
    align-content: flex-start;

    &.time{

    }
    &.users{
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .social-metrics{
        .small{

            &:last-child{
                margin-left:3px;
            }
        }
    }

    .sub-header{
        left: 20%;
    }

    .tag-timeline-container{
        display: flex;
        flex-direction: row;

        padding: 7px;
    }
    .tag-timeline-wrapper{
        display: flex;
        flex-direction: row;
        // border-bottom: 1px dotted #111d1d;
        padding: 0px 4px;
        // background: #3333334a;

        .social-item-tag{
            margin: 7px;
        }
    }
    .tag-timeline-left-column{
        margin: 3px 0;
        background: rgba(33, 33, 33, 0.64);
        width: 15px;
        position: relative;
    }

    .tag-timeline-right-column{
        margin: 3px 0;
        background: rgba(33, 33, 33, 0.64);
        width: 15px;
        border-radius: 0 $radius-md $radius-md 0;
        position: relative;
        overflow: hidden;
    }
    .tag-timeline{
        flex: 1;
        position: relative;
        align-items: center;
        display: flex;
        margin: 3px 0;

        background: rgb(33 33 33 / 64%);

        .timeline-user{
            position: absolute;
            top: 10px;
            height: 24px;
            width: 300px;
            // border-left: 1px dotted $color-brand;
            display: flex;
            flex-direction: row;

            .tag-user-image{
                // margin-left:11px !important;
            }
        }

        .time-line-dotted{
            position: absolute;
            top:50%;
            opacity: .3;
            width:100%;
            border-top: 1px dotted #444;
        }
        .time-line-end, .time-line-start{
            position: absolute;
            top:50%;
            width: 5px;
            height: 5px;
            margin-left: -2px;
            margin-top: -2px;
            border-radius: 50%;
            background: #444;
            opacity: .55;
        }

        .time-tick{
            position: absolute;
            top: 50%;
            width: 1px;
            height:4px;
            background: $color-button-action;
        }

        .time-line-start{
            right:0px;
        }
        .time-line-end{
            left: 0;
        }
        .tag-post{
            position: absolute;
            width: 8px;
            height: 8px;
            margin-left: -4px;
            background: $color-brand;
            border-radius: 50%;
            cursor: pointer;
            z-index: 1;
            opacity:.55;
            &:hover{
                transform: scale(1.2);
                background: $color-brand;
                z-index: 99;
                opacity:1;
            }
            &.active{
                background: $color-brand;
                z-index: 33;
                opacity:1;
            }
            &.mute{
                filter:saturate(0);
                opacity: .11;
            }

        }
    }

    .tag-info{
        // padding: 7px;
        position: relative;
        width: 240px;
        // background: $color-panel-sub;
        background: $color-panel-data;
        margin: 3px 0;
        border-radius: $radius-md 0 0 $radius-md;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .tag-users{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 0px 7px 5px 7px;
        margin-top:7px;
    }
    .tag-user-image{
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 0px;
        box-shadow: $shadow-tight;
        border: 2px solid transparent;
        cursor: pointer;
        &:first-child{
            margin-left: 0 !important;
        }
        &:hover{
            border-color: $color-brand !important;
            z-index: 99;
        }
        &.active{
            border-color: $color-brand;
            z-index: 33;
        }
        &.mute{
            filter:saturate(0);
        }
        img{
            max-width: 100%;
        }
    }
}




.list-wrapper{
    overflow-y: auto;
    max-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}




.social-header2{
    .coin-item{
        pointer-events: none;
        border: 0 !important;
    }
    background: $color-panel-sub-item;
    padding: 7px;
    border-radius: $radius-sm;
    box-shadow: $shadow-panel-sub;
    position: absolute;
    z-index: 99;
    margin: 7px;
    min-width: 280px;
}


.social-user-page{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 44px 44px 0 44px;
    p,h5,h4,h3{
        margin: 0;
    }

    .social-user-image{
        border-radius: 50%;
        width:33px;
        height:33px;
        img{
            max-width: 100%;
            max-height: 100%;
        }
        overflow: hidden;
    }
}



.coin-filters{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 7px;
    // flex-wrap: wrap;
}

.profile-image{
    width:24px;
    height:24px;
    // max-width: 24px;
    // max-height: 24px;
    &.large{
        width:33px;
        height:33px;
        // max-width: 33px;
        // max-height: 33px;
    }
    &.xlarge{
        width:53px;
        height:53px;
    }

    &.xxl{
        width:88px;
        height:88px;
    }

    &.shadow{
        box-shadow: $shadow-panel-sub;
    }
    border-radius: 50%;
    
}


.social-card,.social-item, .social-page{
    flex-direction: column;
    // padding: 15px;
    &:not(.social-page){
        background: $color-panel-sub-opaque;
        // background: transparent;
    }
    

    
	// &:hover{
	// 	background: $color-panel-sub-item-roll;
    //     background: #090909;
    //     background: #585858;
	// }
	// &.active{
	// 	background: $color-panel-sub-item-active;
    //     background: #090909;
    //     background: #585858;
	// 	cursor: default;
    //
	// }
	p{
        margin: 0;
        // margin-left: 5px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 600;

        &.ellipsis{
            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &.content{
            padding: 5px;
            margin-top: 7px;
            margin-bottom: 7px;
            word-wrap: break-word;
        }
	}
}

.social-page{
    width:100%;
    .social-page-header{
        padding-top:22px;
        background: $color-panel-sub-opaque;
        display: flex;
        flex-direction: column;
        position: relative;
        // border-radius: $radius-lg;
        border-bottom: 1px solid rgb(35 35 35 / 87%);
    }
    
    // justify-content: space-between;
    position: relative;
    display: flex;

    .social-page-footer{
        position: relative;
        justify-content: center;
        display: flex;
        padding-bottom:11px;
    }
}


.word-break{
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.social-card{
    cursor: pointer;
    display: inline-block;
    &.ghost{
        // cursor: pointer;
    }
    width: 148px;
    height: 173px;
    margin-right: 9px;
    margin-bottom: 9px;
    padding: 15px 10px 5px 10px !important;
    justify-content: space-between;
    position: relative;
   
    box-shadow: $shadow-panel-sub-light;

    border: 1px solid transparent;

    .bio{
        word-break: break-word;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    background: $color-panel-sub-item;

    &:hover, &.active{
		background: $color-panel-sub-item-roll;
        background: #090909;
	}
    &:hover{
        border-color: $color-button-action;
    }
    &.active{
        border-color: $color-button-action;
    }

    .social-card-footer{
        position: relative;
        display: flex;
        justify-content: center;
    }
}

.social-item{
	width: 100%;
    max-width: 500px;
    min-width: 320px;
    display: flex;
    background: #0f1216ad;
	border-right: $color-panel-sub-item-border;
    // border-radius: $radius-md;
    margin: 0;
    flex: 1;
    width: inherit;
    &:last-child{
        margin: 0;
        border-right: none;
    }
	align-items: center;
    justify-content: flex-start;
	// cursor: pointer;
	position: relative;
    max-height: calc(100vh - 44px);
    overflow: auto;
    overflow-x: hidden;
    // padding-top:33px;



    .social-item-header, .social-item-header-micro{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .social-item-header{
        padding: 0 17px 13px;
        width: 100%;
        margin-top: -33px;
        justify-content: space-between;
        p{
            margin-left: 7px;
        }

    }

    .social-item-header-micro{

  
        padding: 7px 10px 5px 6px;
        position: sticky;
        top: 0px;
        z-index: 11;
        background-color: $color-panel-sub-dark;
        background-image: url(/media/textures/dropbar3.png);
        background-repeat: repeat-x;
        min-width: 100%;
        margin: 0;
        justify-content: space-between;
        // border-radius: 0 0 5px 5px;

        // -webkit-backdrop-filter: blur(3px);
        // backdrop-filter: blur(3px);

        -webkit-transition: translate opacity 0.2s linear;
        -moz-transition: translate opacity 0.2s linear;
        transition: translate opacity 0.2s linear;

        img, .profile-image {
            width: 20px;
            height: 20px;
        }

        // justify-content: space-between;
        p{
            margin-left: 7px;
            font-size: 11px;
        }
    }

    .social-item-posts{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 33px;
    }
    .social-item-post{
        max-width: 94%;
        padding: 11px 6px 8px 8px;
        margin: 5px 0px;
        display: flex;
        flex: 1;
        width: 92%;
        flex-direction: column;
        display: flex;
        background: $color-panel-sub-item;
        border-radius: $radius-md;
        position: relative;
        .time{

            font-size: 11px;
            font-weight: bold;
            opacity: .77;
            color: $color-text-medium;
        }
        &:hover{
            // background: $color-panel-light;
            time{
                color: $color-brand;
            }
        }
        .social-item-post-header{
            padding: 5px;
            padding-top: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .retweet-tag,.social-item-tag{
            .svg-icon{
                svg{
                    fill: $color-text;
                }
                margin-right: 3px;
            }
            display: flex;
            align-items: center;
            margin-bottom: 8px;
        }

        .retweet-tag{

            .social-item-tag{
                margin-bottom: 0;
            }
        }



        .social-item-media-container{
            width: 100%;
            margin-top:7px;
            .social-item-media{
                width: 100%;
                display: flex;
                border-radius: $radius-md;
                overflow: hidden;
                justify-content: center;
                align-items: center;
                max-height: 50vh;
                background: #222;
                margin-bottom: 7px;
                img,video{
                    max-width: 100%;
                    max-height: 50vh;
                }
            }
        }

    }

    &.disabled{
        cursor: default;
        pointer-events: none;
        filter: saturate(0) brightness(.7);
    }
    &:first-child{
        border-top: 0;
    }


    .active-green-circle{
        right:initial;
        left:7px;
    }

    .active-red-circle{

    }



}

.social-item-tags{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.social-item-tag-container{
    position: relative;
    display: flex;
    // margin: 7px;
    &.roll{
        z-index: 999;
    }

    &:hover{
        .social-item-tag-options{
            pointer-events: all;
            opacity: 1 !important;
        }
    }
}

.social-item-tag-options{
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: calc( 100% - 18px );
    max-width: 40px;
    height: 100%;
    top:0;
    left: 7px;
    background: $color-grey-darker;
    cursor: pointer;
    z-index: 0;
    border-radius: $radius-sm;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-18px);
    height: 20px;
    // padding-bottom: 5px;
    padding: 0 4px;
    .svg-icon{
        width: 16px;
        height: 16px;
        margin: 0;
    }
    &.roll{
        pointer-events: all;
        // z-index: 999;
        opacity: 1;
    }
    &:hover{
        background: $color-grey-darkest;
    }
}

.social-item-tag{
    padding: 1px 4px 2px 4px;
    margin-right: 4px;

    font-size: 12px;
    /* line-height: 12px; */
    font-weight: 600;



    border: 1px solid transparent;
    border-radius: $radius-sm;
    background: rgb(0 77 122 / 50%);
    max-width: 100%;
    max-height: 20px;
    // background: $color-orange;
    // color: $color-grey-dark;
    cursor: pointer;
    &.interactive{
        &:hover, &.active{
            border-color: $color-button-action;
            color: $color-grey-darker !important;
        }
    }
    &:hover, &.active{
        background: $color-button-action;
        border-color: $color-button-action;
        p{
            color: $color-grey-darker;
        }
    }
    &.hide{
        display: none !important;
    }
    &.retweet{
        display: flex !important;
    }



    p{
        margin: 0;
        // margin-left: 5px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 600;
    }

    .count-circle{
        margin-left: 5px;
    }

    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;




    &.cash{
        background: $color-green;
        color: $color-grey-dark;
        &:hover, &.active{
            background: $color-button-action;
            color: $color-text;
        }
    }

    &.hash{
        background: $color-purple;
        background: $color-brand-blue;
        color: $color-grey-dark;
        &:hover, &.active{
            background: $color-button-action;
            color: $color-text;
        }
    }

    &.user, &.retweet{
        background: $color-blue;
        color: $color-grey-dark;
        &:hover, &.active{
            background: $color-button-action;
            color: $color-text;
        }
    }

    p{
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
        overflow: hidden;
    }


}
