/*******************************************
Coin Styles
*******************************************/

.chain-portfolio-header{

    background: rgba(14,16,18,.64);
    position: fixed;
    display: flex;
    flex-direction: row;
    // padding: 5px 5px;
    z-index: 999;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    height: $page-header-height;

    border-radius: $radius-md;
    margin-top: 14px;
    margin-left: 14px;
}

.portfolio-chain-container{
    display: flex;
    flex-wrap: wrap;
    padding-top: 53px;
}

.portfolio-chain{
    &:first-child{
        // margin-top: 64px;
    }
    // flex: 1 1;
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    // background: $color-panel-sub;
    //
    // background: rgb(7 8 8 / 47%);
    // padding: 15px;
    margin: 8px 15px;
    border-radius: $radius-md;
    position: relative;

    width: 160px;
    min-width: 160px;

    .portfolio-chain-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        background: #000;
        background: #0000004d;
        border-radius: $radius-md $radius-md 0 0;
    }
    .coin-icon{
        width: 20px;
        height: 20px;
    }
    .active-green-circle{
        top:19px;
        right:15px;
    }
}
.portfolio-chain-footer{
    display: flex;
    padding: 5px;
    background: #0000004d;
    border-radius: 0 0 $radius-md $radius-md;
}

.chain-title{
    font-size: 12px;
    // text-transform: uppercase;
    font-weight: bold;
    margin-left: 7px;
}

.chain-tokens-container{
    padding: 10px;
    background: rgb(28 31 33 / 40%);
}

.chain-tokens{
    border-radius: $radius-md;
    overflow: hidden;
    // margin:10px;
}

.coin-list{
    display: flex;
}

.coin-filters{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 7px;
    // flex-wrap: wrap;
}


.coins-items-container{
    width:100%;
    // height:auto;
    height: calc( 100vh - 156px );

    &.exchange{
        height: 60vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 4px;
    }
    // margin-bottom:22px;
    // min-height:300px;
    // background: $color-panel-sub;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 45px;
}

.coin-data{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    position: relative;
}

.token-symbol-icon{
    &.large{

        .coin-icon-wrapper{
            // max-width: 57px;
            width: initial;
        }
    }

}

// .coin-item:nth-child(odd){
//     background: $color-panel-sub-item;
// }
// .coin-item:nth-child(even){
//     background: $color-panel-sub-item-dark;
// }

.coin-item:nth-child(odd){
    background: $color-panel-sub-opaque;
}
.coin-item:nth-child(even){
    background: $color-panel-sub-opaque-dark;
}


.dex-pair-balance{
    &.space{
        margin-left:4px;
        border-bottom-left-radius: $radius-md;
        border-bottom-right-radius: $radius-md;
        overflow: hidden;
        margin-bottom: 4px;
        margin-top: -3px;
    }
}

.dex-pair-balance{
    // max-width: 720px;
    z-index: -3;
   
    &.featured{
        max-width: 664px;
        border-radius: $radius-md;
        overflow: hidden;
    }
}


.coin-item{

    display: flex;
    flex-direction: row;
    padding: 8px;
    padding-right: 14px;
	border-top: $color-panel-sub-item-border;
	align-items: center;
    justify-content: space-between;
	cursor: pointer !important;
	position: relative;
    border: 1px solid;
    border-color: #22222280;
    overflow: visible;
    box-shadow: $shadow-panel-sub-light;
    // border-radius: $radius-md;
    min-height: 128px;
    // max-width: 720px;
  
    .token-symbol{
        min-width:177px;
        max-width:177px;
    }

   
    &.featured{
        min-height: 164px;
        max-width: 664px;
        .dex-pair-balance{
            
    
        }
        .token-symbol{
            min-width:184px;
            // max-width:177px;
        }
        .token-symbol-icon{
            margin-right: 11px;
            img{
                max-width: 59px;
                margin-right: 4px;
            }
        }
    }

    &.no-margin{
        margin: 0 !important;
    }

    &.small{
        // max-width: 640px;
    }
    
    &.space{
        width: calc( 100% - 0px );
        border-radius: $radius-md;
       
        // margin: 5px 4px;
        // margin: 4px 4px 0 4px;
    }

    &.rank-animation{
        // width: 100%;
        // transition: translateY 1000ms ease;
    }

    .data-item{
        padding:0 !important;
    }
    &.disabled{
        cursor: default;
        pointer-events: none;
        filter: saturate(0) brightness(.7);
    }
    &:first-child{
        // margin-top: 4px;
        // border-top: 0;
        // margin-top: 7px;
    }
    &.exchange{
        border-radius: $radius-md;
    }

    .chain-icon{
        width: 24px;
        height: 24px;
        max-width: 24px !important;
        max-height: 24px !important;
        &.large{
            width: 44px;
            height: 44px;
            max-width: 44px !important;
            max-height: 44px !important;
        }
        margin-left: 3px;

    }
    .caution-tag{
        font-size: 10px;
        min-width: 44px;
    }

    .coin-balance{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        margin-right: 7px;
        color: $color-panel-title-subtle;
        font-weight: bold;

    }
    .token-balance{
        font-weight: 500;
        font-family: 'Roboto Mono', monospace;
        color: $color-button-action;
        font-size: 14px;
        &.small{
            font-size: 12px;
        }
    }

    .active-green-circle{
        right:initial;
        left:7px;
    }

    .svg-icon{
        svg{
            width:24px;
            height:24px;
        }
    }
    .coin-symbol{
        // font-size: .6em;
    }

    img, .identicon{
        // width:20px !important;
        // height:20px !important;
        // max-width: 24px;
        // max-height: 24px;
        // border-radius: 50%;
        // box-shadow: $shadow-panel-sub;
    }
	&:hover, &.active{
		background: $color-panel-sub-item-roll;
        background: #090909;
	}
    &:hover{
        border-color: $color-button-action;
    }
    &.active{
        border-color: $color-button-action;
    }
	&.active{
		// background: $color-panel-sub-item-active;
		cursor: default;
        // background: #090909;
	}
	p{
		margin: 0;
		margin-left: 5px;
	    line-height: initial;
	    font-size: 11px;
        &:not(.no-wrap){
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
            overflow: hidden;
        }
        
	}
}

.coin-list-wrapper{
    margin-top: 88px;
    overflow-y: auto;
    max-height: 100vh;
}

.coin-meta{
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    font-size: 11px;
    font-weight: bold;
    // margin-left: 7px;
    // max-width: 44%;


}

.coin-name,.coin-symbol{
    // max-width: 108px;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;

}

.minimal{
    .coin-name,.coin-symbol{
        line-height: 12px;
    }
}

.coin-name{
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    // color: $color-panel-title;
    margin-bottom: 3px;
    line-height: 16px;
    margin: 0;
}
.coin-symbol{
    font-size: 11px;
    font-weight: normal;
    opacity: .84;
    font-weight: bold;
    line-height: 13px;
    margin: 0;
    // text-transform: uppercase;
}

.coin-icon-wrapper{
    // width: 27px;
    margin-right: 5px;
}

.mute{
    opacity: .55;
}

.coin-price{
    font-size: 11px;
    font-weight: bold;
    position: absolute;
    right: 10px;
    bottom: 10px;
    .coin-e{
        position: relative;
        // font-family: var(--chakra-fonts-mono);
        font-size: 0.8em;
        top: 0.2em;
        padding-inline: 0.2em;
    }
}

.price::first-letter{
    font-size: 0.8em;
    opacity: 0.73;
    margin-left: 2px;
    margin-right: 2px;
}
.coin-24-change{
    font-size: 9px;
    font-weight: bold;
    margin-bottom: 5px;

}
.coin-price::first-letter {
    opacity: .5;
}
.coin-rank{
    position: absolute;
    top:5px;
    right:5px;
    font-size: 10px;
    font-weight: bold;
    opacity: .3;
}
.coin-rank::before {
  content: "#";
  opacity: .5;
}



.nft-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 7px;
    padding-top:59px;
}

.nft-item{
    width: $nft-width;
    display: flex;
    flex-direction: column;
    background: $color-panel-sub-item;
    border-radius: $radius-md;
    position: relative;
    .nft-art-container{
        position: relative;
        width: 100%;
        display: flex;
        // min-height: 240px;
    }

}

img.nft-art, video.nft-art{
    // padding: 3px;
    // border-radius: $radius-sm;
    border-radius: 5px;
    aspect-ratio: 1;
    object-fit: contain;
    background: $color-panel-sub;
    max-width: 100%;
    max-height: 100%;
    // img{
    //     max-width: 100%;
    //     max-height: 100%;
    // }
    // video{
    //     background: #fff;
    // }
}
video.nft-art{
    background: #fff;
}



.nft-project, .nft-project-card{
    .nft-header{
        padding: 11px;
        .coin-meta{
            align-items: center;
            margin-left: 0;
        }
        .coin-name{

            font-size: 11px;
        }
        .coin-symbol{
            font-size: 10px;
            margin-right: 7px;
        }
    }
    .nft-item{
        .coin-meta{
            padding: 7px;
            padding-bottom: 10px;
            flex-direction: row;
            align-items: center;
        }
    }
}


.nft-stage-toolbar{
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: row;


    background-color: rgba(28, 31, 33, 0.7);
    background-image: url(/media/textures/dropbar3.png);
    background-repeat: repeat-x;
    backdrop-filter: blur(12px);
    padding: 7px 11px;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 30%);
    // bottom: 0;
    &.top{
        top: 0;
        background-image: url(/media/textures/dropbar3invert.png);
        background-repeat: repeat-x;
        border-radius: 0 0 6px 6px;
    }
    &.bottom{
        bottom: 0;
        border-radius: 6px 6px 0 0;
    }
    // left: 50%;
    // // width: 120px;
    // margin-left: -60px;
    // left: 0;
    // right: 0;
    // margin-left: auto;
    // margin-right: auto;
    z-index: 33;
    align-items: center;
    // width:520px;
    margin: auto;

    &.center{
        left: 50%;
        transform: translateX(-50%);
    }

    @include break-max($break-med) {
        left: 0;
        top: initial;
        bottom: 0px;
        margin: 0;
        width: 100% !important;
        // border-radius: 0  !important;
    }

    &.footer-toolbar{
        display: none;

        @include break-max($break-med) {
            display: flex;
            max-width: 100%;
            border-radius: 0;
        }

        bottom: 0;
        max-width: 444px;
        margin: auto;
    }


}

.nft-project{

    // padding:25px;
    // padding-top: $page-header-height;
    display: flex;

    /* flex: 1; */
    height: 100%;
    // align-items: center;
    flex-direction: column;
    position: relative;

    .svg-icon.bluecheckmark{
        width: 18px;
        height: 18px;
        margin-left: 7px;
        svg{
            fill: $color-brand-blue !important;
        }
    }






    .nft-project-header{
        width: 100%;
        height: 200px;
        max-height: 20vh;
        min-height: 80px;
        &.fullscreen{
            height: 0;
            border: 0;
            min-height: 0;
            .nft-stage-toolbar{
                bottom: -41px;
                border-radius: 0 0 6px 6px;
            }
        }
        .nft-stage-toolbar{
            @include break-max($break-med) {
                display: none;
            }
        }

        @include break-max($break-med) {
            height: 0;
            border: 0;
            min-height: 0;
        }


        background: #333;
        position: relative;
        border-bottom: $border-section;
        z-index: 3;

    }


    .nft-header{

        padding: 10px 13px;
        position: fixed;
        /* width: 100%; */
        display: flex;
        flex-direction: row;
        padding: 5px;
        margin: 17px;
        background: $color-panel-sub;
        border-radius: 7px;
        align-items: center;
        right: 15px;
        bottom: 15px;

        .coin-meta{
            flex: none;
            margin-right: 15px;

            .coin-name{
                // font-size: 2.0em;
                // line-height: 30px;
            }
            .coin-symbol{
                // font-size: 1.2em;
                // line-height: 17px;
            }

        }
    }
    .nft-stage-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .nft-items{
        padding: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .nft-item{

        }
    }

    .nft-art-container{

    }

    .nft-item{
        cursor: pointer;
        // width: 240px;
        display: flex;
        flex-direction: column;
        background: rgba(16, 16, 16, 0.64);

        border-radius: 6px;
        margin: 7px;
        border-radius: 4px;
        // overflow: hidden;
        box-shadow: $shadow-panel;

        &.full{
            cursor: default;
            // pointer-events: none;
            background: none;
            width: 100% !important;
            max-width: initial !important;
            max-height: 100% !important;
            box-shadow: none;
            &:hover{
                background: none;
            }
            .nft-art-container{
                max-height: 100%;
                max-width: 100%;
            }
        }
        &:hover{
            background: rgba(16, 16, 16, .84);
        }


    }

    &.presentation{
        position: relative;



        .nft-stage{
            display: flex;
            width: 100%;
            max-height: calc( 100vh - $main-header-height - 4vh );
            position: relative;
            justify-content: center;
            align-items: center;
            height:100%;


        }

        .nft-item{
            // margin: 0 !important;
            .nft-art-container{
                // align-items: center;
                justify-content: center;
            }
        }
        .nft-thumbs{
            display: flex;
            overflow: auto;
            max-width: 100%;
            height: 100%;
        }

    }

}



.nft-project-banner{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.nft-project-meta-column{
    position: absolute;
    top: 0;
    left: 0;
    padding-top:10px;
    padding-left:10px;
    display: flex;
    flex-direction: column;
    height:100%;

}

.nft-project-meta-container{

    display: flex;
    flex-direction: row;
    align-items: center;

    @include break-max($break-med) {
        transform: scale(.7);
        transform-origin: left top;
        // top:0;
        // left:-37px;
        .nft-project-meta{
            background: none;
        }
    }

    &.full{
        transform: scale(.7);
        transform-origin: left top;
        // top:0;
        // left:-37px;
        .nft-project-meta{
            background: none;
        }
    }
    // .coin-meta{
    //     transform: scale(1.2);
    //     flex-direction: column;
    // }
}
.nft-project-meta{
    display: flex;

    flex-direction: column;
    background: $color-panel-sub;
    border-radius: $radius-sm;
    padding: 7px;
    margin: 7px;
    .nft-project-title{
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        line-height: 18px;
    }

    .nft-project-title-sub{
        font-size: 12px;
        font-weight: 600;
        margin:0;
    }
}

.nft-project-image{
    cursor: pointer;

    // bottom: -40px;
    width: 80px;
    height: 80px;
    border-radius: $radius-xl;
    border: 4px solid $color-grey-dark;

    overflow: hidden;
    box-shadow: $shadow-panel;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    // img{
    //     max-width: 100%;
    //     max-height: 100%;
    // }
}

.nft-thumbs{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px !important;
    background: $color-panel-main;
    border-bottom: $border-section;
    padding: 4px;
    max-height: 360px;
    overflow: auto;
    .nft-item{
        border: 2px solid transparent;
        width: 32.3%;
        margin: .5%;
    }
}

.nft-traits{
    padding: 7px;
    .nft-trait{
        width: 100%;
        padding: 7px;
        background: $color-panel-sub;
        border-radius: $radius-md;
        margin: 3px;
        box-shadow: $shadow-tight;
        min-height: 64px;

    }
}





.nft-project-card{

    cursor: pointer;
    // max-width: $nft-project-width;
    // width: 31%;
    background: $color-panel-sub;
    margin: 7px;
    border-radius: $radius-md;
    background: $color-panel-sub;
    &:hover{
        background: $color-panel-sub-roll;

    }
    overflow: hidden;
    box-shadow: $shadow-tight;

    .nft-item-slug{
        display: flex;
        pointer-events: none;
        border-radius: 0;
        flex: 1 1;
        min-width: 40%;
        font-size: 31px;
        align-items: center;
        justify-content: center;
        background: $color-panel-sub-item;
        div::first-letter {
            opacity: .5;
        }
    }

    .nft-item{

        pointer-events: none;

        border-radius: 0;
        // width: 50%;

        &:first-child{
            &:not(:last-child) {
                border-right: 1px solid #000;
            }

        }
        &:nth-child(2){
            border-right: 0;
        }
        &:nth-child(3){
            border-top: 1px solid #000;

        }
        &:nth-child(4){
            border-top: 1px solid #000;
            border-left: 1px solid #000;
        }
        flex: 1;
        // margin: 5px;
        min-width: 40%;
        &:first-child{
            width: 100%;
        }

        &:hover{
            // background: rgba(16, 16, 16, .84);
            img{
                transform: scale(1.1);
            }
        }

    }
}




.coin-meta{
    display: flex;
    flex-direction: row;
    &.column{
        flex-direction: column;
    }

    justify-content: space-between;
    // align-items: center;
    max-height: 40px;
    // background: $color-panel-sub;


}

.coin-links{
    display: flex;
    &.center{
        align-items: center;
    }
    &.disabled{
        pointer-events: none;
        .coin-link{
            cursor: default;
            .svg-icon{
                opacity: .33;
            }
        }

    }

    &.bw{
        .coin-link{
            *{
                filter: saturate(0)
            }

            &:hover,&.active{
                *{
                    filter: none;
                }
            }
        }
    }

    .coin-link{
        background-position: center;

        &.disabled{
            pointer-events: none;
            
            cursor: default;
            .svg-icon{
                opacity: .33;
            }
            > *{
                opacity: .33;
            }
    
        }

        cursor: pointer;
        margin: 2px;
        padding: 8px;
        background: #333;
        border-radius: 4px;
        // background: $color-panel-button;
        background: linear-gradient(352deg, rgba(19, 23, 27, 0.84) 0%, rgba(53, 57, 62, 0.54) 99%);
        &.full{
            background: $color-panel-button-solid;
        }
        // background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        // max-width: 36px;
        // max-height: 28px;
        max-height: 32px;
        min-width: 36px;
    
        p{
            opacity: .7;
            margin: 0px;
            font-size: 13px;
            font-weight: 600;
        }

        &.null{
            pointer-events: none;
        }
        [class^=icon-], [class*=" icon-"] {
            font-size: 17px;
            margin: 0;
        }

        &:hover, &.null, &.active{
            // transition: background .5s;
            background: $color-panel-button-roll;
            background: $color-panel-button-roll radial-gradient(circle, transparent 1%, $color-panel-button 1%) center/20000%;
            p{
                opacity: 1;
            }
            &.no-fill{
                .svg-icon{
                    
                    svg{
                        fill: none;
                        path{
                            fill: none;
                        }
                    }
                    
                }
            }
            .svg-icon{
                &:not(.no-fill){
                    svg{
                        fill: $color-icon-roll;
                        path{
                            fill: $color-icon-roll;
                            stroke: $color-icon-roll;
                        }
                    }
                }
                svg{
                  
                    path{
                        
                        stroke: $color-icon-roll;
                    }
                }
                img{
                    filter: none;
                }
            }
            [class^=icon-], [class*=" icon-"] {
                color: $color-icon-roll;
            }
        }

        &.active-border{
            border: 1px solid transparent;
        }


        &.active{
            // background-color: $color-panel-button-roll;
            background-size: 100%;
            transition: background 0s;
            &.active-border{
                border: 1px solid $color-brand;
            }
        }


    }

    &.token-toolbar{
        .coin-link{
            // max-width: 24px;
            // max-height: 24px;
            img{
                max-width: 100%;
                max-height: 100%;
                // filter: saturate(0) brightness(0.8);
                filter: grayscale(100%) contrast(100%) brightness(100%) sepia(30%) hue-rotate(172deg);
                &:hover{
                    filter: grayscale(100%) contrast(100%) brightness(100%) sepia(70%) hue-rotate(146deg);
                }
                // &:hover{
                //     filter: saturate(0) brightness(1);
                // }
            }
        }
    }

    .svg-icon{
        width:17px;
        height:17px;

        &.large{
            width:22px;
            height: 22px;
        }

        img{
            max-width: 100%;
            max-height: 100%;
           // filter: saturate(0.47);
           filter: saturate(0.9) sepia(1) brightness(.7) hue-rotate(153deg);
        }
        &:not(.no-fill){
            svg{
                width: 100%;
                height: 100%;
    
                fill: $color-icon;
                path{
                    fill: $color-icon;
                    stroke: $color-icon;
                }
            }
        }
        
        &.telegram{
            svg{
                fill-rule:evenodd;
                // clip-rule:evenodd;
                // stroke-linejoin:round;
                // stroke-miterlimit:0.11421;

            }
        }
    }

    &.large{
        .svg-icon{
            width:22px;
            height:22px;
        }
        .coin-link{
            margin: 7px;
            padding: 8px;
        }
    }
}


.currency{
    font-weight: 500;
    font-family: "Roboto Mono", monospace;
    // color: #d3caca !important;

    &.dexter{
        color: #698892 !important;
    }
}


.blue{
    color: $color-blue !important;
}
.green{
    color: $color-green !important;
}

.blue-verified-bg{
    background: rgba(0, 140, 255,.73);
    // border:1px solid #aaa;
    
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
.blue-verified{
    color:rgb(0, 140, 255);
}

.bluebg{
    background: $color-blue;
}
.greenbg{
    background: $color-green;
}
.red{
    color: $color-red !important;
}
.yellow{
    color: $color-yellow !important;
    svg{
        fill: $color-yellow;
        stroke: $color-yellow;
        path{
            fill: $color-yellow;
            stroke: $color-yellow;
        }
    }
}
.neutral {
    color: $color-text-medium;
}
.small{
    // font-size: 0.84em;
    // opacity: .5;
}



.coin-header{
    .coin-item{
        pointer-events: none;
        border: 0 !important;
    }
    background: $color-panel-sub-item;
    padding: 7px;
    border-radius: $radius-sm;
    box-shadow: $shadow-panel-sub;
    position: absolute;
    z-index: 99;
    margin: 7px;
    min-width: 280px;
}
.coin-e{
    font-size: 77%;
    top: 0.4em;
    padding-inline-start: 0.2em;
    padding-inline-end: 0.2em;
    position: relative;
    opacity: .7;
}
