/*******************************************
Token Styles
*******************************************/


.token-data{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 22px;
    background: #13131744;
    
    // background: #1d2024;
    .identicon{

        width: 26px !important;
        height: 26px !important;
        margin: 4px;
    	/* margin-right: 15px; */
    }
}

.sidebar{
    .token-data{
        flex: 1;
    }
}


.identicon{
    border-radius: 50%;
}






.token-list{
    max-height: calc(100vh - $main-header-height);
    overflow-y: auto;
    padding-top: 55px;
    flex: 1;
    height: 100%;
    overflow-x: hidden;
}

.token-filters{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 7px;
    // flex-wrap: wrap;
}


.data-value a{
    border-bottom:1px dotted #47687c;
    &:hover{
        text-decoration: none;
        border-bottom:1px dotted $color-text;
    }
}

.token-balance-dex{
    margin-bottom: 15px;
    // background: rgb(28 51 57 / 46%) !important;
    max-height: 52px;
    // background: rgb(6 30 36 / 46%) !important;
    background: rgb(18 50 70 / 46%) !important;
    // -webkit-backdrop-filter: blur(4px);
    // backdrop-filter: blur(4px);
    &.data-panel{
        margin: 0;
        border-radius: 0;
        padding-top: 7px;
    }
    .data-item{
        justify-content: space-between;
    }

    .svg-icon{
        width: 14px;
    }
    .data-value{
        display: flex;
        flex-direction: column;
        text-align: right;
        color: $color-text;
        // text-shadow: rgb(61, 206, 197) 0px 0px 16px;
        &.green{
            color: $color-green;
        }
        
       
        .small{
            color: $color-text;
            font-size: .8em;
            &:not(.no-prefix){
                &::first-letter{
                    opacity: .5;
                    margin-right: 2px;
                    color: $color-icon-active;
                    color: $color-text;
                    font-family: "Saira", "Montserrat", "Source Sans Pro", "Helvetica", sans-serif !important;
                }
            }
            
        }
        .usd-balance,.eth-balance{



        }
    }
    p{
        margin: 0;
        font-size: 14px;
        line-height: 16px;
    }


}

.data-item{
    .price{
        &:not(.no-prefix){
            &::first-letter{
                opacity: .5;
                margin-right: 2px;
                color: $color-icon-active;
                color: $color-text !important;
    
            }
        }
        
    }
}

.balanceBlur{

    .currency, .token-balance{
        &:not(.no-blur){
            filter: blur(4px);
        }
        

        .eth-balance, .usd-balance{
            &:not(.no-blur){
                filter: blur(4px);
            }
        }
    }
}

.access-allowed{
    &.walletBlur{
        .address{
            &.wallet-address{
                &:not(:hover){
                    p{
                        filter: blur(4px) !important;
                    }
                    
                }
            }

            &.wallet-address-p{
                &:not(:hover){
                   
                    filter: blur(4px) !important;
                    
                    
                }
            }
        }
    }
}


.currency, .token-balance{
    
    .eth-balance{
        display: flex;
        flex-direction: row;
        text-align: right;
        color: $color-text;
        margin: 0 !important;
        padding: 4px 7px;
        background: #24242494;
        align-items: center;
        border-radius: 4px;
      
        .small{
            &::first-letter{
                font-family: "Saira", "Montserrat", "Source Sans Pro", "Helvetica", sans-serif !important;
            }

        }
        span{
            margin-right: 3px;
        }

        &::first-letter{
            // opacity: .5 !important;
            color: $color-icon-active;
            font-family: "Saira", "Montserrat", "Source Sans Pro", "Helvetica", sans-serif !important;
        }

    }

    .usd-balance{
        &.solana{
            display: flex;
        }
        &:not(.no-prefix){
            &::first-letter{
                opacity: .5 !important;
                color: $color-icon-active;
                margin-right: 4px;
                font-family: "Saira", "Montserrat", "Source Sans Pro", "Helvetica", sans-serif !important;
            }
        }

    }


}


.portfolio-header{
    .currency{
       
        *{
            // font-size: 18px !important;
        }

    }


}

.token-symbol-icon{
    // min-width: 137px;
    min-height: 45px;
    .data-column{
        flex: 1;
        min-width: 64px;
    }
    .data-icon{
        min-width: 33px;
        &.coin-icon-wrapper{
            min-width: 43px;
            margin-right: 8px;
        }
        img{
            border-radius: 50%;
            border-radius: 7px;
        }
    }
}

.token-copy-container{
    width: 100%;
    position: relative;
    min-height: 26px;

    .data-panel{
        margin: 2px 7px;
    }
    .data-title{
        min-width: 72px;
        padding-right: 7px;
        margin-left:4px;
        /* text-align: right; */
        max-width: 72px;
        word-break: break-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color:#dbdbdb;
    }
    p{
        margin: 0;
    }

    .small{
        transform: scale(.7);
    }

    .contract{
        color: $color-text !important;
        border-radius: $radius-md;
        padding: 0 4px;
    }

    .address{
        align-items: center;
        &:hover{

            .contract{
                background: $color-panel-main;
            }

        }
        @include break-max($break-sm) {
    		flex-direction: column;
            align-items: flex-start;

            .currency{
                margin:0;
                padding:0;
            }
    	}



    }

    h6{
        margin-bottom: 0px;
    }

    @include break-max($break-sm) {

        margin-bottom: 11px;

        .address{
            flex-direction: column;
            align-items: flex-start;

            .currency{
                margin:0;
                padding:0;
            }
        }

        h6{
            margin-bottom: 7px;
        }

    }


}




.chart-embed{
    width: 100%;
    iframe{
        border: 0;
        width: 100%;
    }
}

.token-dex-update-timer{
    position: absolute;
    right: 20px;
    font-size: 11px;
    font-weight: 600;
}

.token-pair-footer{
    font-size: 11px;
    font-weight: 600;
    // margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 20px;
    // position: absolute;

    bottom: 0;
    padding: 10px;
    background: $color-panel-data;
    z-index: 7;
    .coin-links{
        button{
            width: 17px;
            height: 17px;

            &.brandcolor{
                svg{
                    path{
                        fill: $color-brand-blue;
                    }
                }

            }
        }


    }

    button{
        margin: 3px;
        svg{
            margin: 0;
            path{

            }
        }
        &.brandcolor{
            svg{

                path{
                    fill: $color-brand-blue;
                }
            }
        }
        &:hover{
            &.brandcolor{
                svg{

                    path{
                        fill: $color-black;

                    }
                }
            }
        }
    }
}

.action-bar{
    position: relative;

    button{
        font-size: 12px;
        // border-right :rgb(40 40 40 / 71%);
        // border-bottom: rgb(40 40 40 / 71%);
        // border-top: rgb(40 40 40 / 71%);
        // border-color: 
        background: linear-gradient(rgba(31, 34, 37,.77), rgba(21, 24, 27, .77));

        background: linear-gradient(to bottom, #555 0%, #333 70%, #111 100%);
        background: linear-gradient(to bottom, #2b2b2b 0%, #333 60%, #222222 100%);

        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), inset 0 1px 2px rgba(255, 255, 255, 0.1);

        &:hover, &.active{
            background: linear-gradient(to bottom, #0d8aff 0%, #1d5bad 60%, #133d62 100%);
            // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6), inset 0 1px 2px rgba(255, 255, 255, 0.1);
            // background: linear-gradient(to bottom, #666 0%, #444 70%, #222 100%);
        }

        &:last-child{
            border-right:0;
        }
    }
    
}

.trending-channels{
    top: 48px;
}
.tg-snipers{
    top: 38px;
    width: 100%;
}


.action-bar .tg-snipers.show, .trending-channels.show{
	// border: 1px solid #233137;
	border: 1px solid rgb(68 109 125 / 53%) !important;
    border-top: 0 !important;
}
.tg-snipers, .trending-channels{
    padding: 0 !important;
    position: absolute;
    
    // display: flex;
    flex-direction: column;
    // flex-flow: column-reverse;
    height: auto;
    transform: translateY(0);
    background:rgba(11,11,11,.64); 
    backdrop-filter: blur(10px);
    
    // backdrop-filter: blur(3px);
    // background: rgb(20 21 22 / 37%);
    -webkit-transition: transform $drawer-duration;
    -moz-transition: transform $drawer-duration;
    -o-transition: transform $drawer-duration;
    transition: transform $drawer-duration;
    display: none;

    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
    transition: all 1s ease;
    

    :last-child{
        // border-bottom-left-radius: 7px;
        // border-bottom-right-radius: 7px;
    }

    z-index: 99;
    &.show{
        display: flex;
        // border: 1px solid #333333;
        border-top: none;
        // transform: translateY(-37px);
    }
}

.health-check{

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 13px;
    // min-height: 33px;
    margin-top: 3px;

    .data-row{
        align-items: center;
        padding: 0;
        .data-title{
            // width: 57px;
            text-align: right;
            margin-right: 7px;
        }
        .data-value{
            font-size: 11px;

        }
    }
    .honeylogo{
        position: relative;
        // margin-right: 7px;
        .active-green-circle{
            right:22px;
        }
        img{
            filter: saturate(0);
            width: 19px;
            height: 19px;
        }

    }
    .caution-tag{
        transform:scale(.8);
        margin-left: 7px;
    }


}

.caution-tag{
    &:first-child{
        margin-top:5px;
    }
    background: $color-red;
    &.orange{
        background: $color-orange;
    }
    color: $color-grey-dark;
    padding: 3px 7px;
    border-radius: $radius-md;
    font-size: 12px;
    font-weight: 600;
    width: auto;
    max-width: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
}



.socket-panel{
    position: absolute;
    z-index: 9999;
    display: flex;
}
